import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // debug: true,
    // fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          welcome: "Welcome Back",
          goldlevel: "Gold level",
          yourpoints: "YOUR POINTS",
          scanuniquecode: "Register Unique Code",
          myQuest: "My Quest",
          redemptionhistory: "Redemption history",
          myrewards: "POINT SHOP",
          keyinunique: "KEY IN UNIQUE CODE",
          latestpromo: "LATEST PROMOS EXCLUSIVELY FOR YOU",
          withyourpoints: "WITH YOUR POINTS, YOU CAN REDEEM",
          seemore: "SEE MORE",
          seemoreprocap: "See more",
          shoponline: "Shop online now for doorstep delivery.",
          moregoodies: "MORE GOODIES FOR YOU",
          vindamall: "Vindamall",
          lazada: "Lazada",
          shopee: "Shopee",
          scancode: "Scan Code",
          scancodecap: "REGISTER CODE",
          inbox: "INBOX",
          profile: "PROFILE",
          home: "Home",
          signinto: "Sign in to view your current membership tiers",
          signin: "Sign in",
          myquest: "MY QUEST",
          dbcforum: "DBC FORUM",
          freesample: "FREE SAMPLE",
          freesamplelow: "Free Sample",
          helpcenter: "HELP CENTER",
          pointshop: "POINT SHOP",
          sharealittle: "Share a little comfort",
          babyproducts: "Baby products",
          lifestyle: "Lifestyle",
          educational: "Educational",
          points: " Points",
          getfree: "GET FREE SAMPLE",
          referfriendsandbe: "Refer friends and be rewarded",
          quest: "Quest",
          waysto: "Ways To Collect More Points",
          needassist: "Need assistance",
          howtoredeempoints: "How to redeem points",
          whatrewards: "I Like to Try Your Sample",
          ourstory: "Our Story",
          privacy: "Privacy Policy",
          contactus: "Contact Us",
          terms: "Terms",
          condition: "Conditions",
          useofcook: "Use of Cookies",
          allrights: "2021 DRYPERS MALAYSIA ALL RIGHTS RESERVED",
          products: "Products",
          sustainable: "Sustainability",
          dbc: "Drypers Baby Club",
          latesthappen: "Latest happenings",
          parentingtip: "Parentings tips",
          shop: "Shop",
          settings: "Settings",
          login: "LOG IN",
          setdigits: "Set the 10 digit unique code within the scanning zone",
          keyin: "Key in instead",
          registercode: "Register Unique Code",
          youcanalso:
            "To prevent error, please make sure you only click once after entering the code",
          theuc: " the Unique Code manually",
          submit: "SUBMIT",
          registered: "Registered",
          youhaveregistered: "You have successfully register",
          youmayredeem: "you may have redeem at",
          pointshoplow: "Point Shop",
          orderhistory: "ORDER HISTORY",
          myvoucher: "MY VOUCHER",
          mymysterybox: "MYSTERY BOX",
          current: "Current",
          history: "History",
          redeemnow: "Redeem now",
          redeemnowcap: "ADD TO CART NOW",
          donateNow: "DONATE NOW",
          termsandcon: "Terms and conditions",
          delivery: "Delivery Details",
          fillinaddress: "Fill in your new delivery address",
          name: "Name",
          email: "Email",
          fullname: "Full Name",
          dob: "Date of Birth",
          edb: "Expected due date",
          password: "Password",
          contactdetails: "Contact Details",
          phonenumber: "Phone Number",
          emailaddr: "Email Address",
          address: "Address",
          addrline1: "Address Line 1",
          addrline2: "Address Line 2",
          streetname: "Street Name",
          city: "City",
          state: "State",
          postcode: "Postcode",
          country: "Country",
          work: "Work",
          others: "Others",
          addaddress: "Add New Address",
          savethisaddress: "Save this address?",
          areyousure:
            "Are you sure you want to save this address? You can always edit them later",
          save: "Save",
          edit: "Edit",
          chooseaddress: "Choose your delivery address",
          confirm: "Confirm",
          additem: "Add Item",
          myredemption: "My redemption point",
          mypoint: "My point balance",
          trackorder: "TRACK YOUR ORDER",
          orderplaced: "Order Placed",
          youhavemade: "You have made your order on",
          orderplace: "Order place",
          orderprocessed: "Order processed",
          wearepreparing: "We are preparing your order",
          preparetoship: "Preparing to ship",
          orderhasbeen: "Order has been shipped",
          orderreceived: "Order received",
          activateaccount: "Activate your account to continue",
          scantoactivate: "Scan a unique product code to activate your account",
          maybelater: "Maybe later",
          signintocontinue: "Sign in to continue",
          signintodrypers:
            "Sign in to your drypers baby club account to redeem rewards",
          signintoreview:
            "Sign in to review your current rewards from drypers baby club",
          redeemrewards: "Redeem rewards",
          areyousuretoredeem:
            "Are you sure you want to redeem this reward?Your points will be deducted once details confirmation are done",
          vouchernum: "Voucher number",
          howtouse: "HOW TO USE",
          pointshistory: "Points History",
          pointsearned: "Points Earned",
          pointsused: "Points Used",
          tierbenefits: "Tier Privileges",
          latestreward: "Latest rewards",
          collectpoints: "Tips to collect points",
          completequest: "Complete quest",
          account: "Account",
          editprofile: "Edit profile",
          personalinfo: "Personal Information",
          rewards: "Rewards",
          refernearn: "Refer & Earn ",
          help: "Frequently Asked Questions",
          race: "Race",
          gender: "Gender",
          male: "Male",
          female: "Female",
          nationality: "Nationality",
          babyinfo: "Baby Information",
          myaddress: "My Address",
          addchild: "Add Child",
          currentstage: "Your current stage",
          childbirthday: "Child's birthday",
          childgender: "Child's Gender",
          sharelist: "Share list",
          babydiaper: "Baby Diaper Brand",
          babyproduct: "Baby Product",
          diaperformat: "Diaper Format",
          diapersize: "Baby Diaper Size",
          refernow: "Refer your friends now and get 500 points",
          refernowPlatinum: "Refer your friends now and get 550 points",
          refernowDiamond: "Refer your friends now and get 600 points",
          copycode: "Copy refferral code",
          receivecurrentupdate:
            "Sign in to receive current upates from drypers baby club",
          search: "Search",
          faq: "Frequently asked questions",
          browseby: "Browse by topic",
          rewardsredemp: "Rewards redemption",
          newquest: "New quest unlocked",
          letscelebrate: "Let's celebrate 64 years of merdeka.",
          learnmore: "Learn more",
          forum: "Forum",
          recommended: "Recommended",
          wouldyoulike: "Would you like a free sample?",
          yes: "Yes",
          no: "No",
          yescap: "YES",
          nocap: "NO",
          maybecap: "MAYBE LATER",
          nameerror: "Please avoid using special characters",
          numbererror: "You have entered an invalid phone number",
          emailerror: "You have entered an invalid email address",
          selectsize: "Select Size",
          selectformat: "Select Format",
          noavailableformat: "No available format",
          noavailablesampleswith:
            "No available samples with this size and format",
          pleaseselect: "please select other size and format",
          noavailablesamples: "No available samples",
          register: "Register",
          signupasmember: "Sign up as a member to get free samples",
          signupbelow: "Or sign up below",
          noofchild: "No. of Children",
          number: "Number",
          dobchild: "Date of Birth of Child",
          referralcode: "Insert Referral Code (if have)",
          existingmember: "Existing member?",
          logincap: "LOGIN",
          registercap: "REGISTER",
          error: "Error",
          notRegistered:
            "This user is not registered. Please head to sign up page.",
          forgetpassword: "Forgot Password?",
          orconnectusing: "Or connect using",
          donthaveaccount: "Don't have an account?",
          signup: "SIGN UP",
          ourstorycap: "ABOUT US",
          aboutuscap: "OUR STORY",
          productscap: "PRODUCTS",
          sustaincap: "SUSTAINABILITY",
          dbccap: "DRYPERS BABY CLUB",
          latesthapcap: "LATEST HAPPENINGS",
          parentingcap: "PARENTING TIPS",
          FAQcap: "FAQ",
          shopcap: "SHOP NOW",
          neverbe: "Never be caught without Drypers!",
          getfreecap: "GET FREE",
          samplescap: "SAMPLES",
          refercap: "REFER FRIENDS",
          berewardcap: "BE REWARDED",
          questcap: "QUEST",
          waystocap: "WAYS TO COLLECT POINTS",
          collectpointscap: "COLLECT POINTS",
          atleastten: "The code has to be at least 10 digits.",
          exploredbc: "Explore Drypers Baby Club",
          stayconnected: "Stay connected with us",
          all: "All",
          evouchers: "Evouchers",
          pregnantOffer: "Pregnant Offer",
          orderid: "Order ID",
          prodpurc: "Product Purchased",
          pts: " pts",
          orderdate: "Order Date",
          thisitem:
            "This item is not available for redemption, please select another item",
          outofstock: "Out of stock",
          usernotfound: "User not found, please refresh and try again",
          insuffpoints: "Insufficient points",
          insuffipoints:
            "Sorry you have insufficient points to continue this transaction. Thank You.",
          validuntil: "Valid until",
          applicable: "Applicable for",
          allprod: "ALL products",
          purchat: "purchased at",
          eachvouc:
            "Each eVoucher is valid for one (1) transaction, (1) time use only.",
          nonreturn: "Non-returnable, non-refundable, and not extendable date.",
          freeshiip:
            "FREE shipping applicable for purchases above RM50 after discount",
          moreinps: "More in Point Shop",
          delivaddr: "DELIVERY ADDRESS",
          getyourdrypers: "Get your Drypers free sample today!",
          sorry: "Sorry",
          trynow: "Try Now",
          youalreadyre:
            "You have already redeemed your free sample. You can redeem it again in 3 months",
          yourfeedback: "Your Feedback Is Highly Appreciated",
          itseems:
            "Share with us your feedback on the sample pack that you have received and be rewarded with 50 club points!",
          sharefeedback: "Share Feedback",
          haveyoureceived: "Have You Received The Free Sample?",
          doyouhaveany:
            "Do you have any comments you would like to share to us?",
          continue: "CONTINUE",
          tq: "Thank You!",
          tqPopup:
            "Thank you for registering for Drypers Baby Club, Please login again",
          wehavereceive: "We have received your feedback!",
          wehavereceive2:
            "Thank you for your response. Your points has been credited!",
          tqlocation:
            "Thank you for your time and we hope you enjoyed your free sample!",
          tqnotlocation:
            "Thank you for your time to share your feedback with us",
          confirmorder: "Confirm Order?",
          areyouorder: "Are you sure you want to confirm your order placement?",
          requestsent: "Free Sample Request Sent!",
          yoursent: "Your free sample request has been sent.",
          receivesample:
            "You will receive your selected free sample in 7 days.",
          totalredeem: "Total Redeemed Points",
          mybalpts: "My Points Balance",
          inboxlow: "Inbox",
          clearall: "Clear All",
          letcelebrate: "Let's Celebrate 64 Years of Merdeka",
          uploadpicture: "Upload Picture To Join Contest",
          label: "Label",
          labellong: "Label (eg. Home, Office)",
          houseno: "House No.",
          stage: "Stage",
          trying: "We are trying for a baby",
          pregnant: "I'm pregnant / my partner is pregnant",
          oneChild: "I have one child",
          moreChild: "I have more than one child",
          date: "Date",
          brand: "Brand",
          format: "Format",
          size: "Size",
          done: "DONE",
          babysaved: "Baby information saved successfully",
          back: "BACK",
          emailaddress: "Email Address",
          usemyrefer: "Use my refer code to register into this programme.",
          share: "SHARE",
          logoutcap: "LOG OUT",
          logout: "Log Out",
          firstcuddle: "A Million First Cuddles Begin Here",
          babyfirstcry:
            "From Your baby's first cry to your baby's first step. Drypers Baby Club is here to raise the next generation with you - accompanied with lots of cuddles in between! Our club makes parenthood so much more enjoyable by rewarding you with freebies and gifts, plus exciting promotions along the way. Sign up now for a world of benefits and privileges!",
          becauseparent:
            "BECAUSE EVERY PARENTS & BABIES DESERVE TO BE REWARDED",
          welcomegift: "WELCOME GIFT *",
          freegoodies: "Free goodies for you and your baby",
          asanewmember:
            "As a new member, you will get a welcome gift upon signing up. Welcome to the club!",
          freesamples: "FREE SAMPLES",
          bethefirst: "Be the first to receive free samples!",
          getfirstdips:
            "You get first dibs! Drypers diapers are made for best fit. As a member, you'll receive samples to try out on your baby.",
          redeempoints: "REDEEM POINTS FOR ATTRACTIVE GIFTS",
          turnpoints: "Turn points into amazing gifts",
          eachtime:
            "Each time you buy Drypers products, you can collect points which you can use to redeem gifts from our points shop.",
          pregnancy: "PREGNANCY AND PARENTING GUIDE",
          babybumbum: "Babies, bum-bum and beyond",
          whetherplan:
            "Whether you're planning, expecting or currently juggling an infant and two toddlers, we've got all the info you need in baby-sized pieces of information. Let's get on this parenting journey together!",
          apply: "apply",
          diapertype: "Diaper Type",
          fbsignup: "Facebook sign up",
          googsignup: "Google sign up",
          mobile: "Mobile",
          producttype: "Product Type",
          readourprivacy: "Please read our Privacy Policy and Terms of use.*",
          ihaveread: "I have read and understand the",
          infoonper: "information concerning personal data",
          iagreewith: "and I agree with the",
          termsofuse: "terms of use",
          yesiliketo:
            "Yes, I would like to receive information from Drypers in the future",
          loginexisting: "LOGIN AS EXISTING MEMBER",
          drysamplereq: "Drypers Sample Request",
          offerschild:
            "Drypers offers your child the best in comfort and flexibility.",
          tryiton:
            "It is ideal for both day and night usage. Try it on your child to see the difference Drypers can make.",
          onlyonesample:
            "Sample requests from same household within 3 months will not be entertained.",
          additional:
            "Sample requests for the same household with multiple addresses will not be entertained.",
          multiple:
            "Sample requests for the same baby using multiple addresses will not be entertained.",
          validaddress:
            "Valid for members with Malaysian postal addresses only.",
          incompleteentries: "Incomplete entries will not be processed.",
          timetaken:
            "Time taken for sample delivery is between 2 - 3 weeks. Samples are sent out using normal postage service.",
          righttopostpone:
            "Vinda Marketing (M) Sdn Bhd has the right to postpone or cancel this program without any prior notice.",
          quests: "Quests",
          findouthowcan:
            "I Registered but Have Yet to Receive the Activation E-Mail",
          dryperforum: "Drypers Baby Club Forum",
          scanproductcode: "SCAN PRODUCT CODE",
          scanproductcodeDesktop: "KEY IN UNIQUE CODE",
          doquests: "DO QUESTS",
          referfriends: "REFER FRIENDS",
          pointscap: "POINTS",
          prodcampclub: "Product, Campaigns, Club",
          proreleemail: "Product Enquiry",
          campreleemail: "Drypers Baby Club Enquiry",
          clubreleemail: "Promo/Campaign Enquiry",
          foranyfurther: "Contact us at 1-800-88-9988 ",
          // oremailat: "or email us at",
          chatbot: "Chat With Us",
          // integratewih: "(integration with Yellow Messenger)",
          time: "(Mon to Fri, 9am to 5.30pm)",
          signupnow: "Sign Up Now",
          waystotitle1: "Register Unique Code",
          waystotitle2: "Complete Quest",
          waystotitle3: "Refer Friends",
          waysto1:
            "The unique code is the valid proof of purchase required to collect points from Drypers Point Shop.",
          waysto2:
            "In Drypers Touch, Drypers Wee Wee Dry and Drypers Classic packs you will find it on the inner side of the pack just underneath the Weight/Size indicator.",
          waysto3:
            "In the Drypers DryPantz packs, you will find it on the inner side of the pack beneath the Weight/Size indicator, just next to the barcode area.",
          waysto4:
            "In the Skinature by Drypers packs, you will find it at the top of the packaging.",
          waysto5: "Receive points based on the",
          waysto6: "Participating baby pack",
          waysto7:
            "You can earn extra loyalty points by completing the prestige quests",
          waysto8: "Go to quest",
          waysto9: "Select the quest you want to complete",
          waysto10: "Complete the quest",
          waysto11: "Earn the points upon completion!",
          waysto12: "Refer a friend to Drypers Baby Club and earn 500 points!",
          waysto13: "Refer a friend in 3 simple steps:",
          waysto14: "Go to Profile, Select Refer & Earn",
          waysto15: "Share Your Referral Code to Your Friends",
          waysto16:
            "Get rewarded 500 points with each of the friends you refer.",
          fptext:
            "Enter the email address you registered with, and you will receive a link to reset your password.",
          fp: "Forgot Password",
          senta: "We have sent a new password to",
          junk: 'If you have not received it in your inbox, check in the "spam" or "junk mail"',
          return: "Return to the login page",
          resetTitle: "Reset Password",
          enternewpwd: "Enter your new password",
          // enternewpwd: "Enter your new password",
          confirmnewpwd: "Confirm your new password",
          resetDescription:
            "Our website has now been updated for a better experience for you. For account security please reset your password.",
          weSent: "Check",
          checkSpam:
            "inbox and click the link provided in the email to reset your password.",
          comingSoon:
            "Drypers Baby Club site going through an upgrade to serve you better! We will be back on the 17th of March, 11.00am.\n\nIf you are unable to register your unique codes or earn the double points, don't worry, you have another chance to earn double points on the 22nd of March!\n\nFor enquiries, please reach out to us at our toll free line 1-800-88-9988 or email us at my.drypersbabyclub@vinda.com",
          wrongpwd: "You've entered the wrong password",
          updatemypwd: "Update my password",
          sendmemypwd: "Send me my password",
          succ: "Success",
          successresetmsg:
            "You have successfully reset your password. You may now login using your new password.",
          returntologin: "Return to the login page",
          helptitle1: "How to Collect Points?",
          helptitle2: "How to Redeem Baby Products?",
          helptitle3: "How to Redeem eVouchers?",
          helptitle4: "How to Request for a Sample",
          helptitle5: "HELP",
          help1:
            "You can earn points for as long as you are a Drypers Baby Club member. Your membership in the Drypers Baby Club lasts until your child turns five.",
          help2: "You can collect points by:",
          help3: "Purchasing selected Drypers diaper packs",
          help4: "Answering or scoring points in email questionnaires",
          help5: "Referring friends to Drypers Baby Club",
          help6:
            "Taking part in certain on-going promotions by Drypers Baby Club",
          help7:
            "Join now and start your rewarding journey as a Drypers Baby Club member!",
          tnca: "*Terms & Conditions Apply",
          help8:
            "You can redeem Baby Products from the Point Shop using the points you have collected.",
          help9: "Steps:",
          help10: "Go to Point Shop at the Homepage.",
          help11: "Click the Baby Product tab in the Point Shop catalogue.",
          help12: "Choose the Baby Product that you want to redeem.",
          help13: "Click “Add To Cart Now”.",
          help14: "Click “Confirm”.",
          help15:
            "You can redeem eVouchers from the Point Shop using the points you have collected.",
          help16: "Go to Point Shop at the Homepage.",
          help17: "Click Evouchers tab in the Point Shop catalogue.",
          help18: "Choose the eVouchers that you want to redeem.",
          help19: "Click “Redeem Now”.",
          help20:
            "To try out the free samples, you need to register as a Drypers Baby Club member. Registration is free of charge and becoming a Drypers member entitles you to tall the club benefits.",
          help21: "Go to https://drypers.com.my/drypers-baby-club/picksample",
          help22: "Select your free sample and size.",
          help23:
            "Login to your account / If you haven't gotten an account, sign up as a member.",
          help24: "Confirm your delivery address.",
          help25:
            "Free Sample Request shall be sent to your delivery address, and you shall receive your selected free sample in 4-6 weeks for delivery",
          help26: "What Rewards am I entitled to?",
          help27:
            "Find the rewards list at Point shop as in Latest Reward, it will display all the rewards you are entitled to.",
          help28: "How do I get the Free Sample?",
          help29:
            "Click on FREE SAMPLE, the product lists are swipeable, choose your desired product, select size and you will be directed to the product description page, click GET FREE SAMPLE and confirm your delivery address. And the Free Sample Request will be sent and you shall receive your selected free sample in 4-6 weeks for delivery.",
          help30: "How do I eligible for free sample?",
          help31:
            "Only one sample request per baby per household is allowed with a total of 4 sample requests over a 2-year period. Additional sample request from same household for the same baby within 3 months will not be entertained. Sample requests for the same baby using multiple addresses will not be entertained. Valid for members with Malaysian postal addresses only. Incomplete entries will not be processed. Vinda Marketing (M) Sdn Bhd has the right to postpone or cancel this program without any prior notice.",
          help32:
            "How should I provide the Feedback regarding the Free Samples after I received?",
          help33:
            "14 days after you submit your free sample request, you will find this in the Free Sample section, share comments with us if you have any.",
          help34: "How do I get inform on latest promotions?",
          help35:
            "Click Inbox at the bottom of the page and click on one message ribbon to get to know more information about the offering messages.",
          help36: "How do I contact the support team?",
          help37:
            "Click Help Center, when you need assistance, click on FAQ section on Homepage. Contact us if your queries are not answered in the FAQ section,you can shoot us an email at CONTACT US, fill in your queries and we will get back to you as soon as we can.",
          helptitle6: "Single Sign-On FAQ:",
          helpsso:
            "I have an account at both Drypers Baby Club and Vindamall, which email address should I use to log in?",
          helpsso2:
            "If the email address at both Drypers Baby Club and Vindamall are the same, you may use the email address and password combination from either accounts.",
          helpsso3: "What if I don't have a Vindamall account?",
          helpsso4:
            "You may log in with your Drypers Baby Club email address and password.",
          helpsso5:
            "What if my Vindamall account has a different email address than my Drypers Baby Club account?",
          helpsso6:
            "A new Drypers Baby Club account will be created upon first time login to Drypers Baby Club with Vindamall email address and password.",
          helpsso7:
            "Based on the 3 questions above, this means that I will have 2 Drypers Baby Club accounts. Which Drypers Baby Club account should I use?",
          helpsso8:
            "To continue your progress at Drypers Baby Club, you may log in with your existing Drypers Baby Club account.",
          helpsso9:
            "If I choose to continue with my existing Drypers Baby Club account, what will happen to the new Drypers Baby Club account created?",
          helpsso10:
            "It will be a new account starting at 0 points, and will remain linked to your Vindamall account.​",
          helpsso11: "Why do I need to reset my password?",
          helpsso12:
            "You will be requested to reset your password to strengthen it according to the latest password requirement.",
          helpsso13:
            "Will resetting password impact my Drypers Baby Club points?",
          helpsso14:
            "Rest assure, your Drypers Baby Club points will not be affected.",
          helpsso15: "What is the new required password format?",
          helpsso16:
            "Password must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 digit, and 1 special character (@$!%*?&).",
          helpsso17:
            "What happens to the points history and membership tier status?",
          helpsso18:
            "Points history and membership tier are not affected by Single Sign-On. If you have both Drypers Baby Club and Vindamall accounts registered with different email addresses, when you log in to Drypers Baby Club with your Vindamall email address and password, a new Drypers Baby Club account will be created starting at 0 points.​",
          helpsso19:
            "What is the advantage of logging in to Vindamall and Drypers Baby Club with the same email address?",
          helpsso20:
            "Hassle-free login to either accounts at Drypers Baby Club and Vindamall.",
          helpsso21:
            "Where can I find more information about logging in to Drypers Baby Club or Vindamall with the same email address and password?",
          helpsso22: "Click on ",
          helpsso22b: "<Vindamall & Drypers Baby Club Login Guide> ",
          helpsso22c: "for more information.",
          blurrycam: "Blurry cam?",
          switch: "Switch Camera",
          nothing: "Nothing Here",
          uq: "Unique Code",
          participatingdryper:
            "Participating Drypers Diaper Packs For Points Collection",
          helpinfo1:
            "To try out the free samples, you need to register as a Drypers Baby Club member. Registration is free of charge and becoming a Drypers member entitles you to all the club benefits. Go to https://www.drypers.com.my/drypers-sample-request/ . Select your free sample and size, login to your account, if you haven’t gotten an account, sign up as a member. Then confirm your delivery address, and the Free Sample Request will be sent and you shall receive your selected free sample in 4-6 weeks for delivery.",
          helpinfo2:
            "Check your inbox including your junk and spam mail folders",
          helpinfo3:
            "If you fail to receive an activation email, Contact us to get further help from the Support Team",
          desktopShare:
            "If you are unable to share the referral code via this page, please try using your mobile",
          questComingSoon: "Watch this space! More quest are coming soon.",
          pointshopChoice: "POINT SHOP",
          maintenance:
            "Dear Members, we are experiencing some instability in our Point Shop section. We will resume once it has been rectified. Thank you for your patience.",
          newAddress:
            "Dear Members, To prevent any disruptions in our fulfilment service, please provide us with your updated mailing address. Thank you",
          pointMessage:
            "Dear Members, we hear that some of you are experiencing points balance discrepancies. We are currently fixing the issue and have disabled the unique code registration function. You can resume to register your unique codes after 25th of March. Thank you for your patience.",
          yougained: "You have gained ",
          donateDescription:
            "Your Drypers Points is valuable to us. Donate Drypers Points to refurbish 4 orphanages to provide better and brighter environment to live and play.",
          confirmRedeemDesc:
            "Are you sure you want to redeem this reward? Your points will be deducted once details confirmation are done",
          fitAThon: "Fit-A-Thon",
          questsToCollect: "Complete all the activities to get AMAZING prizes!",
          activityOneGrandPrize:
            "Grand Prize: Smart Watch\n + \n1000x consolation prize",
          activityTwoGrandPrize:
            "Grand Prize: Smart Watch\n + \n1000x consolation prize",
          activityThreeGrandPrize:
            "Grand Prize: Smart Watch\n + \n1000x consolation prize",
          activityFourGrandPrize:
            "Grand Prize: Lifetime supply of Drypers\n + \n1000x consolation prize",
          activityOneDesc: "Dance with Drypers and CoComelon",
          activityTwoDesc: "Dance with Drypers and CoComelon",
          activityThreeDesc: "Buy Drypers product and win lucky prizes",
          activityFourDesc: "Watch the Zoom Live Session",
          activityOne: "Activity 1",
          activityTwo: "Activity 2",
          activityThree: "Activity 3",
          activityFour: "Activity 4",
          activityOnePeriod: "5 - 14 AUG",
          activityTwoPeriod: "15 - 21 AUG",
          activityThreePeriod: "22 - 27 AUG",
          activityFourPeriod: "28 AUG",
          activityOneTitle: "Activity 1: Fit To Play",
          activityTwoTitle: "Activity 2: Fit For Learning",
          activityThreeTitle: "Activity 3: Fit To Discover",
          activityFourTitle: "Activity 4: Live Stream",
          activityOnePrizeToWin:
            "Prize to win: 1000 x Limited edition Drypers x CoCoMelon T-Shirt \n(For the 1st 1000 participants)",
          activityTwoPrizeToWin:
            "1st Prize 1x Smart Watch!! Price: 1000x Limited edition Drypers CoCoMelon Canvas Bag!!! \n(For the 1st 1000 participants)",
          activityThreePrizeToWin:
            "1st Prize: 1x Smart Watch!! Prize 1000 x Limited edition Drypers x CoCoMelon Canvas Bag!!! \n(For the 1st 1000 participants)",
          activityFourPrizeToWin:
            "Grand Prize: Lifetime supply of Drypers products* + 1 x CoComelon Cabin Bag + Limited Edition Premiums* Consolation Prizes: 1000x Limited Edition CoComelon Medal ",
          activityFourPrizeToWinSmall:
            "*Images are for illustration purposes only",
          stepToJoin: "Steps to join",
          stepToParticipate: "Steps to participate in the activity",
          activityOneStepOne:
            "Find any CoComelon song on Facebook Reels, Instagram Reels, or TikTok and record your baby dancing.",
          activityOneStepOneSmall:
            "*You can use any CoComelon songs in your video.",
          activityOneStepTwo:
            "Share your video post and hashtag #DrypersFitAThon2 #DrypersMalaysia @DrypersMY",
          activityOneStepTwoSmall:
            "*Screenshot your post as proof of participation",
          activityOneStepThree: "You can submit max. 5 entries",
          activityOneStepFour: "Submit your entry for Activity 1 now!",
          activityTwoStepOne: "Play Drypers FIT AR game",
          activityTwoStepTwo:
            "Score a min. of 1,000 points & share your video/picture and hashtag #DrypersFitAThon2 #DrypersMalaysia @DrypersMY",
          activityTwoStepTwoSmall:
            "*Screenshot your post as proof of participation",
          activityTwoStepThree: "Sign Up for",
          activityTwoStepThreePartTwo: ". Click on Fit-A-Thon icon",
          activityTwoStepThreePartThree:
            "and submit your highest score for Activity 2!",
          activityThreeStepOne:
            "Buy Drypers products min. RM30 from 1 - 27 August 2022 in Vindamall/Lazada/Shopee or at any stores near you. Keep the receipt as proof of purchase.",
          activityThreeStepTwo:
            "Find the digits ONE(1) and ZERO(0) in your receipt number or order number.",
          activityThreeStepTwoPartTwo:
            "to view Vindamall/Lazada/Shopee receipt/order no. example. In-store receipt/invoice e.g. AEON, Lotus's.",
          activityThreeStepThree:
            "If your receipt number or order number* contains these two digits, send a screenshot/picture of your receipt",
          activityThreeStepThreePartTwo: "for proof of purchase.",
          activityThreeStepThreeSmall:
            "*Every household is eligible to submit a maximum of 5 entries for each activity.",
          activityFourStepOne: "to reserve your slot.",
          activityFourStepTwo:
            "View the participation ZOOM link upon reservation.",
          activityFourStepThree:
            "Use the ZOOM link to watch the finale live on ZOOM on 8.30PM, 28th August 2022 and you have a chance to win various great prizes!",
          selectYourEntry: "Select Your Entry",
          entryNote: "*NOTE: 1 ENTRY = 100 POINTS USED*",
          entry: "Entry",
          joinNow: "JOIN NOW",
          goBack: "GO BACK",
          notEnoughPoint: "Oops, not enough points to add more entries",
          note: "NOTE",
          entryNoteOne: "1 entry = 100 points",
          entryNoteTwo: "2 entry = 200 points",
          entryNoteThree: "3 entry = 300 points",
          entryNoteFour: "4 entry = 400 points",
          entryNoteFive: "5 entry = 500 points",
          postLink: "Post link",
          uploadScreenshot: "Upload Screenshot",
          selectTShirtSize: "Select",
          tqsubmission: "Thank You for your submission!",
          submitEntries: "Submit the entries?",
          sureToUse: "Are you sure you want to use",
          pointSubmit: "points to submit",
          entriesQuestion: "entries?",
          picSuccessUpload: "Picture successfully uploaded",
          clickHere: "Click HERE to get more points",
          heretag: "<HERE>",
          here: "here",
          herecap: "HERE",
          atHere: "HERE",
          stayTune:
            "Stay tune for winner announcement on our website or Drypers social media platform",
          activityFirstPrize: "First Prize:",
          activityGrandPrize: "Grand Prize:",
          activityOneFirstPrizeItem: "Smart Watch worth up to RM1,500",
          activityTwoFirstPrizeItem: "Smart Watch worth up to RM1,500",
          activityThreeFirstPrizeItem: "Smart Watch worth up to RM1,500",
          activityFourFirstPrizeItem: "Lifetime supply of Drypers products* +",
          activityFourFirstPrizeItemAddition:
            "CoComelon Cabin Bag + Limited Edition Premiums*",
          activityConsolation: "Consolation Prizes:",
          activityOneConsolationDetail: "Limited Edition CoComelon T-shirt",
          activityTwoConsolationDetail: "Limited Edition CoComelon Canvas Bag",
          activityThreeConsolationDetail:
            "Drypers x CoComelon Limited Edition Lunchbox",
          activityFourConsolationDetail:
            "1000x Limited Edition CoComelon Medal",
          reachedMaximum:
            "You've reached the maximum number of entries for this activity",
          shareScore: "Share Your Highest Score!",
          receipt: "Receipt",
          bookingDoneBefore: "(BOOKING DONE 1 WEEK BEFORE LIVE DAY)",
          activity: "ACTIVITY",
          thankbooking: "THANK YOU FOR BOOKING",
          belowLink: "Below is your zoom link on 28th August, \nsee you soon!",
          mayFindLink: "You may also find the link in your registered email",
          joinFitAThon: "JOIN FIT-A-THON now!",
          slotBooked: "Your slot has been booked!",
          notification:
            "Dear valued members, kindly take note that our Pointshop section will undergo maintenance services and redemption activities will be on hold temporary.\nFor any enquiries, please email us at ",
          click: "Click",
          termCon: "for Terms & Conditions",
          cancel: "Cancel",
          refert1:
            "Referrer must make sure referees use a valid email address to register Drypers Baby Club.",
          refert2:
            "Referrers are NOT allowed to create multiple account to refer their friends.",
          refert3:
            "Referrer may refer up to a maximum of five (5) referees in one (1) month.",
          refert4:
            "Newly recommended friend must be contactable via email OR phone number.",
          refert5:
            "Failure to abide the above terms, shall result to cancellation of accounts and points deduction.",
          refert6: "Click",
          refert7: "here",
          refert8:
            "to read the Terms & Conditions of the 'Refer, Earn, and Double Up!' Campaign (21 Nov - 31 Dec 2023)",
          updateDetails: "UPDATE YOUR DETAILS",
          updateText1:
            "Enjoy a better experience by updating your personal details.",
          updateText2: "You will be rewarded 200 points upon completion",
          updatePersonalInfo: "UPDATE PERSONAL INFORMATION",
          whenEdb: "When is your expected due date?",
          confirmUpdate: "CONFIRM UPDATE?",
          confirmDescription:
            "Please make sure all your details are correct as you can only update your details once in 6 months.",
          fillUpNow: "Fill Up Now!",
          successfulUpdate:
            "You have successfully updated your details.​ 200 Points will be credited to your account.",
          updateNow: "Update Now",
          nextButton: "Next",
          backButton: "Back",
          yesButton: "Yes",
          okayButton: "Okay",
          updated: "Updated",
          announcement: "ANNOUNCEMENT",
          announcementText:
            "Please note that our website will undergo maintenance activities on 25/03/2023 between 10.00pm to 12.00am. Pointshop gift redemption & sample requests activities may experience problems at this time. We apologize for any inconvenience. For any enquiries, please email us at ",
          Iam: "I am",
          Iamyears: "I am",
          yearsold: "years old",
          iwish: "I wish to get a",
          thanksforyour: "Thank you for your contributions!",
          donationof: "We have successfully deducted ",
          hadbeendeduct: "from your account.",
          weappreciate:
            "We truly appreciate your generosity & continuous support!",
          confirmdonate: "Confirmation of Points Donation",
          yourkindgesture: "Your points donation will definitely help",
          togeta: "to fulfill his wish ",
          thankyoufor: "Thank you for your contribution!",
          wish: "wish!",
          donatenowwishes: "Donate Now",
          submitwishes: "Submit",
          backwishes: "Back",
          pointswishes: "Points",
          ourJourney: "Share A Little Comfort Journey from 2016 to 2022",
          totalHomeVisit: "Total Homes Visited",
          totalKidsReaches: "Total Kids Reached Out",
          totalPointDonated: "Total Points Donated ",
          yourGenerousPoint:
            "Your generous points contribution to fulfill these lovely kids wishes are highly appreciated",
          joinNowBtn: "Join us now",
          venueBlog: "Venue ",
          donatedfrom: "donated from",
          donatepoints: "points",
          wishingtreemission: "Wishing Tree Mission",
          thankyouRegister: "Your Registration Is Successful",
          youcanlogin:
            "You can login now to request for sample, collect points and redeem attractive gifts!",
          tier: "Membership Tier",
          tierBenefit: "Tiering Privileges",
          tierPointRequired: "Points Earned Required",
          tierBirthday: "Birthday Points",
          tierReferral: "Referral Points",
          tierMultiplier: "Point Multiplier For Each Unique Code",
          firstCodeTier: "For 1st Unique Code Registration",
          tierWelcome: "Welcome Points",
          thankyoudonate: "Thank you for your points donation!",
          willbeback: "We will be back soon.",
          otpText: "Please key in the OTP code sent to your mobile phone.",
          otpHead: "OTP Sent",
          questPopupTitle1: "Hi Mom & Dad!",
          questPopupDesc1: "segment is live NOW!",
          questPopupDesc2:
            "You can earn more points by just completing various quests!",
          currentQuest: "Your current quests",
          completeProfile: "Complete your profile",
          completeProfileDesc: "Update few missing details and earn points!",
          registerFirstCode: "Register Your First Unique Code",
          registerFirstCodeDesc:
            "Key in your first Unique Code and earn extra points",
          gotoQuest: "Go to Quests",
          profileUpdated: "Profile has been updated",
          activKidsNav: "NEW - ActivKidz by Drypers",
          earnMoreQuest: "Earn More Drypers Points in My Quest!",
          childInformation: "Current Child's Information",
          applicableChild: "*Applicable  for children 3 years old and below",
          addChildren: "+ Add Child",
          childrenInformation: "child's Information",
          informasiAnak: "",
          firstChild: "First",
          secondChild: "Second",
          thirdChild: "Third",
          fourthChild: "Fourth",
          fifthChild: "Fifth",
          sixthChild: "Sixth",
          seventhChild: "Seventh",
          eightChild: "Eighth",
          ninthChild: "Ninth",
          tenthChild: "Tenth",
          ucTNCterm: "Terms & Conditions:",
          ucTNC:
            "Special Unique Code* is NOT applicable for Double Points reward every 15th & 22nd of the month and for Point Multiplier of Membership Tier. ",
          ucTNC2:
            "(* Unique Codes from Baby Expo, Campaign basis special codes,etc)",
          selectOption: "Select an Option",
          thankyouFeedback: "Thank you for your feedback.",
          thankyouContact:
            "Kindly reach out to us at 1-800-88-9988 or email us at my.drypersbabyclub@vinda.com for further assistance.",
          tierReferee: "Referee's 1st Unique Code Registration",
          pointReach: "Points to",
          pointRange: "Points and above",
          goldTierPrivilege: "Gold Tier Privileges",
          platinumTierPrivilege: "Platinum Tier Privileges",
          diamondTierPrivilege: "Diamond Tier Privileges",
          tierPoints: "Points",
          viewTierHistory: "View Membership Tier Activity Details",
          memberTierHistory: "Membership Tier Activity Details​",
          tierHelpCenter: "Help Centre",
          viewTieringPrivileges: "View Tiering Privileges",
          tierStatus: "Tier Status",
          tierUpgradeFrom: "Membership Tier",
          tierUpgrade: "Upgrade",
          tierDowngrade: "Downgrade",
          tierFrom: "from",
          tierHighest:
            "You are at the highest tier - Next tier review date is on ",
          tierBy: "by",
          tierGoldEg: "(eg: Key in 170pts, Points earned: 170pts)",
          tierPlatinumEg: "(eg: Key in 170pts, Points earned: 187pts)",
          tierDiamondEg: "(eg: Key in 170pts, Points earned: 255pts)",
          tierLine: "Drypers Baby Club Membership Tier Terms & Conditions",
          tierLine1:
            "1. Drypers Baby Club Points are earned and accumulated, strictly through the registering of unique codes from your purchases on participating Drypers products, and through purchase of participating Drypers Baby Care Products in Vindamall. Points can also be earned from in-club activities.  ",
          tierLine2:
            "2. Drypers reserves the right to determine or amend the allocation of Drypers Baby Club Points for each activity without prior notice.",
          tierLine3: "Drypers Baby Club Tiers:",
          tierLine4:
            "a.     Gold Tier: This tier is applicable to members who have accumulated between 0 and 3,999 Drypers Baby Club Points within a period of 12 months.  ",
          tierLine5:
            "b.     Platinum Tier: This tier is applicable to members who have accumulated between 4,000 and 8,999 Drypers Baby Club Points within a period of 12 months.  ",
          tierLine6:
            "c.     Diamond Tier: This tier is applicable to members who have accumulated 9,000 Drypers Baby Club Points or more within a period of 12 months.",
          tierLine7: "3. The tiering system will be launched on ",
          tierLine7a: "4. Tiering System Launch Date: ",
          tierLine7b: "September 1st, 2023.",
          tierLine7c:
            " From this date onwards, members can start accumulating Drypers Baby Club Points towards their tier status. ",
          tierLine8:
            "Drypers Baby Club Points earned by members will have no expiry date. The points will remain valid until they are used, or the membership is terminated. ",
          tierLine8a: "5. Drypers Baby Club Point Expiry: ",
          tierLine9:
            "The tier review date is set as one day before the anniversary of the member's registration date. On this date each year, the member's accumulated Drypers Baby Club Points will be reviewed to determine their tier status for the upcoming year. ",
          tierLine9a: "6. Tier Review Date: ",
          tierLine10: "7. Tier Upgrade and Downgrade:  ",
          tierLine11:
            "a.     Upgrade: Members will be upgraded to a higher tier when they accumulate the required number of Drypers Baby Club Points for that tier within the 12-month period.  ",
          tierLine12:
            "b.     Downgrade: If a member fails to accumulate the required Drypers Baby Club Points for their current tier within the 12-month period, they will be downgraded to the appropriate lower tier. ",
          tierLine13: "8. Tier Maintenance:",
          tierLine14:
            "a.    Gold Tier Maintenance: To maintain the Gold Tier status, members must accumulate between 0 and 3,999 Drypers Baby Club Points within each 12-month period.  ",
          tierLine15:
            "b.    Platinum Tier Maintenance: To maintain the Platinum Tier status, members must accumulate between 4,000 and 8,999 Drypers Baby Club Points within each 12-month period.  ",
          tierLine16:
            "c.     Diamond Tier Maintenance: To maintain the Diamond Tier status, members must accumulate 9,000 Drypers Baby Club Points or more within each 12-month period. ",
          tierLine17: "9. Benefits and privileges:",
          tierLine18:
            "a.     Each tier will offer specific benefits and privileges as outlined by Drypers Baby Club.  ",
          tierLine19:
            "b.     Benefits and privileges associated with each tier are subject to change at the discretion of Drypers Baby Club.  ",
          tierLine20:
            "c.     Members must refer to Drypers Baby Club's guidelines for a detailed list of benefits and privileges specific to their tier. ",
          tierLine21: "10. Non-Transferability: ",
          tierLine22:
            "a.     Drypers Baby Club Points and tier status are non-transferable, meaning that they cannot be transferred or assigned to another individual.  ",
          tierLine23:
            "b.     Drypers Baby Club Points and tier status cannot be exchanged for cash, non-designated products, or non-designated rewards. ",
          tierLine24: "11. Termination and Suspension",
          tierLine25:
            "a.     Drypers Baby Club reserves the right to terminate or suspend a member's membership and associated benefits and privileges for any violation of Drypers Baby Club's terms and conditions or for any other reasons deemed necessary by Drypers Baby Club. ",
          tierLine26: "12. Modifications: ",
          tierLine27:
            "a.     Drypers Baby Club reserves the right to modify, amend, or update these Terms and Conditions at any time.  ",
          tierLine28:
            "b.     Members will be notified of any changes to the Terms and Conditions through Drypers Baby Club's communication channels. ",
          tierLine29:
            "These Terms and Conditions shall be governed by and construed in accordance with the laws of Malaysia, without regard to its conflict of laws principles. ",
          tierLine29a: "13. Governing Law: ",
          tierLine30:
            "14. By participating in the tiering system, members acknowledge that they have read, understood, and agreed to comply with these Terms and Conditions.",
          tierLine31: "Scenario:",
          tierLine32:
            "Herewith, member registered before 1st Sep 2023 shall be referred to as ‘Existing Member’ and member registered after 1st Sep 2023 shall be referred to as ‘New Member’ ",
          tierLine33: "For example: ",
          tierLine34: "i.    Existing Member ",
          tierLine35: "a.    Joined DRYPERS BABY CLUB 1st July 2023 ",
          tierLine36:
            "b.    Has 4,500 points before Tier launch on 1st Sep 2023 ",
          tierLine37:
            "c.    Automatically assigned Platinum at Tier launch on 1st Sep 2023 ",
          tierLine38: "d.    ",
          tierLine38a: "Earned ",
          tierLine38b: "5,000 points within 12 months from 1st Sep 2023 ",
          tierLine39:
            "e.    Maintain Platinum at tier review date 31st Aug 2024 ",
          tierLine40: "f.    Start new tier cycle as Platinum ",
          tierLine41: "ii.    Existing Member ",
          tierLine42: "a.    Joined DRYPERS BABY CLUB 1st July 2023 ",
          tierLine43:
            "b.    Has 4,500 points before Tier launch on 1st Sep 2023 ",
          tierLine44:
            "c.    Automatically assigned Platinum at Tier launch on 1st Sep 2023 ",
          tierLine45: "d.    ",
          tierLine45a: "Earned ",
          tierLine45b: "9,000 points within 12 months from 1st Sep 2023 ",
          tierLine46:
            "e.    Upgrade to Diamond upon earning 9,000 points and above ",
          tierLine47:
            "f.    Remain as Diamond at next tier review date 31st Aug 2023 ",
          tierLine48: "g.    Start new tier cycle as Diamond ",
          tierLine49: "iii.    New Member ",
          tierLine50: "a.    Joined DRYPERS BABY CLUB 12th Oct 2023 ",
          tierLine51: "b.    ",
          tierLine51a: "Earned ",
          tierLine51b: "2,000 points within 12 months from 12th Oct 2023 ",
          tierLine52: "c.    Maintain Gold at tier review date 11th Oct 2024 ",
          tierLine53: "e.    Start new tier cycle as Gold ",
          tierLine54: "iv.     New Member ",
          tierLine55: "a.    Joined DRYPERS BABY CLUB 12th Oct 2023 ",
          tierLine56: "b.    ",
          tierLine56a: "Earned ",
          tierLine56b: "4,500 points within 12 months from 12th Oct 2023 ",
          tierLine57:
            "c.    Upgrade to Platinum upon earning 4,000 – 8,999 points ",
          tierLine58:
            "d.    Remain as Platinum at next tier review date 11th Oct 2024 ",
          tierLine59: "e.    Start new tier cycle as Platinum ",
          tierLine60: "v.     New Member ",
          tierLine61: "a.    Joined Drypers Baby Club 12th Oct 2023 ",
          tierLine62: "b.    ",
          tierLine62a: "Earned ",
          tierLine62b: "4,500 points within 12 months from 12th Oct 2023 ",
          tierLine63:
            "c.    Upgrade to Platinum upon earning 4,000 – 8,999 points ",
          tierLine64: "d.    ",
          tierLine64a: "Earned ",
          tierLine64b:
            "5,000 points more – total 9,500 points – within 12 months from 12th Oct 2023 ",
          tierLine65:
            "e.    Upgrade to Diamond upon earning 9,000 points and above ",
          tierLine66:
            "f.    Remain as Diamond at next tier review date 11th Oct 2023 ",
          tierLine67: "g.    Start new tier cycle as Diamond ",
          tierLine68:
            "*Upgrading of Membership Tier will be reflected & validated 24hours after confirmation of meeting the qualifier from accumulative Drypers Baby Club Points. ",
          tierLine69: "vi.     Existing Member ",
          tierLine70: "a.    Joined Drypers Baby Club 1st July 2023 ",
          tierLine71: "b.    Has 9,500 points ",
          tierLine71a: "Before ",
          tierLine71b: "Tier launch on 1st Sep 2023 ",
          tierLine72:
            "c.    Automatically assigned Diamond at Tier launch on 1st Sep 2023 ",
          tierLine73: "d.    ",
          tierLine73a: "Earned ",
          tierLine73b: "5,000 points within 12 months from 1st Sep 2023 ",
          tierLine74:
            "e.    Downgrade to Platinum at tier review date 31st Aug 2024 ",
          tierLine75: "f.    Start new tier cycle as Platinum ",
          tierLine76: "vii.     New Member ",
          tierLine77: "a.    Joined Drypers Baby Club 12th Oct 2023 ",
          tierLine78: "b.    ",
          tierLine78a: "Earned ",
          tiierLine78b: "4,500 points within 12 months from 12th Oct 2023 ",
          tierLine79:
            "c.    Upgrade to Platinum upon earning 4,000 – 8,999 points on 15th June 2024 ",
          tierLine80:
            "d.    Remain Platinum at next tier review date 11th Oct 2024 ",
          tierLine81: "<  New 12-months tier cycle begins > ",
          tierLine82:
            "a.    Start new tier cycle as Platinum at 12th Oct 2024 ",
          tierLine83: "b.    ",
          tierLine83a: "Earned ",
          tierLine83b: "3,500 points within 12 months from 12th Oct 2024 ",
          tierLine84:
            "c.    Downgrade to Gold at tier review date 11th Oct 2025 ",
          tierLine85: "d.    Start new tier cycle as Gold ",
          tierLine86: "Frequently Asked Questions (FAQ) ",
          tierLine87: "1. What can I do in Drypers Baby Club? ",
          tierLine88: "- Key in Unique Code to earn Drypers Baby Club Points ",
          tierLine89:
            "- Earn Double Points every 15th & 22nd of the month (Birthday Points, Points Multiplier, Welcome Points, and Referral Points, will not be doubled up on both event dates) ",
          tierLine90: "- Earn Referral Points ",
          tierLine91: "- Enjoy Points Multiplier ",
          tierLine92: "- Earn points by completing quests ",
          tierLine93: "- Redeem rewards with Drypers Baby Club Points ",
          tierLine94: "- More activities coming! ",
          tierLine95: "2. When will my Drypers Baby Club Points expire? ",
          tierLine96:
            "Good news! Drypers Baby Drypers Baby Club Points has no expiry! ",
          tierLine97: "3. Will my tier expire? ",
          tierLine98:
            "Every member’s tier is reviewed in a 12-month cycle based on their tier review date. For more information, please refer to the Terms & Conditions – Tiering System. ",
          tierLine99: "4. What are the privileges of my tier? ",
          tierLine100:
            "Every member’s tier is reviewed in a 12-month cycle based on their tier review date. For more information, please refer to the Drypers Baby Club Membership Tier System Terms & Conditions. ",
          tierLine101: "You can find out more by clicking the ",
          tierLine101a: "tier icon",
          tierLine101b: " on your Drypers Baby Club homepage.",
          tierLine102: "5. When is my tier review date? ",
          tierLine103:
            "If you registered in Drypers Baby Club before 1st Sep 2023, your tier review date is 31st Aug of every year. ",
          tierLine104:
            "If you registered in Drypers Baby Club after 1st Sep 2023, your tier review date is one day before the anniversary of your registration date. ",
          tierLine105: "6. How can I upgrade my tier? ",
          tierLine106:
            "Tier upgrade is based on the points earned within 12 months prior to tier review date. Your tier will be upgraded once you have achieved the tier’s ‘Points Earned Required’. Please refer to the ‘Points Earned Required’ of each tier to achieve the respective tier. ",
          tierLine107: "7. Will my tier downgrade if I am inactive? ",
          tierLine108:
            "Yes, tier downgrade is based on the points earned within 12 months prior to tier review date. If the points earned are insufficient, your tier will be downgraded 1 tier lower. Please refer to the ‘Points Earned Required’ of each tier to achieve the respective tier. ",
          tierLine109:
            "8. Where can I find out the ‘Points Earned Required’ for each tier? ",
          tierLine110:
            "You can find this information when you click on the tier icon on your Drypers Baby Club homepage, or click ",
          tierLine110a: "here",
          tierLine110b: ".",
          tierAt: "at",
          tierHead1: "Drypers Baby Club Membership Tier Terms & Conditions",
          tierHead2: "Frequently Asked Questions (FAQ)",
          uploadImageContest: "Upload Images",
          uploadReceiptContest: "Upload Receipts",
          submitLinkContest: "Submit Link",
          contest: "Contest",
          contestCap: "CONTEST",
          referralError:
            "You have reached the maximum limit of referral per month. Let’s try again next month!",
          referralPopup:
            "This referral code has reached the maximum referral limit for the month.",
          cookiePopup:
            "You have already registered with this device. One device is entitled to register for one account.",
          pinkFongDetails1:
            "Redeem Exclusive Pinkfong Bounce Wonderland Ticket for just 500 club points*!",
          pinkFongDetails2:
            "Enjoy up to 13 inflatable game event worth up to RM65! Don’t miss these special fun moments with your kids. Hurry, LIMITED tickets available!",
          pinkFongDetails3: "*Terms and conditions applied.",
          pinkFongDetails4: "500 points is valid for 1 ticket only.",
          pinkFongDetails5: "Limited to 2 tickets per household.",
          pinkFongDetails6:
            "Tickets are only applicable for children below 16 years old. ",
          pinkFongDetails7:
            "Kids below the age of 3 must be always accompanied by an adult.",
          pinkFongDetails8: "Adults needs to purchase a ticket from this link ",
          pinkFongDetails8a:
            "https://www.ticket2u.com.my/event/33221/bounce-wonderland.",
          pinkFongDetails8b: " RM10 per ticket.",
          pinkFongDetails9:
            "Ticket is only allowed to use during Pinkfong Bounce Wonderland event during the mention date/time below",
          pinkFongDetails10: "While stocks last",
          pinkFongStep1: "Steps to redeem the ticket: ",
          pinkFongStep2: "Redeem your ticket in Drypers Baby Club Pointshop ",
          pinkFongStep3:
            "Key in redemption code at checkout page in this link : ",
          pinkFongStep3a:
            "https://www.ticket2u.com.my/event/33221/bounce-wonderland",
          pinkFongStep3b: " to redeem the FREE ticket ",
          pinkFongStep4: "Ticket Details:-",
          pinkFongStep5: "Date : 22/12/2023 - 7/1/2023",
          pinkFongStep6: "Time : 10am - 930pm",
          pinkFongStep7: "Location : Pavilion Bukit Jalil",
          ssoModalHead: "Congratulations!​",
          ssoModal1:
            "A new Vindamall account has been created with your Drypers Baby Club email and password. Now you are able to login to Vindamall with Drypers Baby Club account.",
          ssoUpdate: "New update:",
          ssoUpdate1: "Single Sign On (SSO) starts on dd/mm/yyyy.",
          ssoUpdate2:
            "Good news! Now you can use the same email to log in to both Drypers Baby Club and Vindamall.",
          ssoUpdate3:
            "For existing Vindamall user, you may use Vindamall email and password to log in to Drypers Baby Club.",
          ssoExist:
            "You have already registered as a Drypers Baby Club member. Please log in to continue.",
          ssoVindamallPassword: "Please log in with Vindamall password.",
          ssoNotRegistered:
            "This email address is not registered. Please proceed to sign up page.",
          ssoCheckPassword: "Please check your password.",
          ssoPasswordValidation:
            "Password must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 digit, and 1 special character (@$!%*?&).",
          ssoVindamallExist:
            "Vindamall account verified. You may proceed to log in with Vindamall email and password. A Drypers Baby Club account will automatically be created for you.",
          passwordNotStrong:
            "Password not strong enough. Please reset your password.",
          passwordNotStrongHeader: "Reset Password to Meet New Requirement",
          wrongPasswordGeneral:
            "The password you entered is incorrect. Kindly check your password. If you still fail to login, kindly proceed to click at Forgot Password & reset your password.",
          internalError: "Internal Error",
          closeButton: "Close",
          bbcReceipt: "BABY CARE RECEIPT",
          receiptSample: "Receipt Sample",
          receiptUploadTnc:
            "Special Unique Code* is NOT applicable for Double Points reward every 15th & 22nd of the month and for Point multiplier of Membership Tier.",
          receiptUploadTnc2:
            "(*Unique Codes from Baby Expo, Campaign basis special codes, etc)",
          clickToUpload: "Click image below to upload receipt and earn points!",
          uploadReceiptPhoto: "Upload receipt photo",
          uploadReceipt: "Upload Receipt",
          supportedFormat:
            "*Supported image format: JPEG, JPG, PNG only (max. 10MB)",
          clearReceipt:
            "*Kindly make sure your receipt is clear with participating product.",
          receiptValidateNote:
            "Note: Your receipt will be validated in 3-5 working days from date of receipt submission",
          storeName: "Online/Offline Store Name*",
          select: "-Select-",
          pleaseSpecify: "Please specify:",
          receiptDate: "Receipt Date*",
          receiptNumber: "Receipt Number*",
          receiptNumberPlaceholder: "Enter receipt number",
          purchasedProducts: "Product(s) Purchased*",
          quantity: "Quantity*",
          quantityPlaceholder: "Enter Quantity",
          addProducts: "Add Products",
          doublePointsNote:
            "Double Points Rewards (15th & 22nd of the month) is applicable based on the receipt upload date. Points will be creditted within 3-5 working days.",
          onlineSample: "Sample of online purchase receipt:",
          offlineSample: "Sample of offline purchase receipt:",
          "Baby Care Product Rewards": "Baby Care Product Rewards",
          areyousuretitle: "Are you sure",
          areyousuretodelete: "Are you sure you want to delete this item",
          delete: "Delete",
          uploadSuccess:
            "Your receipt has been successfully uploaded and will be validated within 3-5 working days.",
          eligibility: "Eligibility",
          eligibilityTnc:
            "This feature is exclusively available to member of the Drypers Baby Club who have purchased Drypers Baby Care Products ONLY.",
          uploadRequirement: "Receipt Upload Requirement",
          uploadRequirementTnc:
            "To earn Drypers Baby Club Points, members must upload a clear image of their purchase receipt featuring eligible Drypers Baby Care Products. Only official receipts from offline & online retailer are accepted, the receipt must clearly display the product description, purchase date, receipt number and store name.",
          eligibleProduct: "Eligible Products",
          eligibleProductTnc:
            "Only purchases of Drypers Baby Care Products qualify for point redemption through receipt upload.",
          pointAllocation: "Point Allocation",
          pointAllocationTnc:
            "Upon successful receipt verification, members will receive points equivalent to the purchase amount of eligible Drypers Baby Care Products. Points will be credited to the member's account within 3 - 5 working days.",
          dpd: "Double Points Day",
          dpdTnc:
            "Double Points Day points reward is applicable based on the receipt upload date, which is on 15th & 22nd of every month.  Points will be credited to the member's account within 3 - 5 working days.",
          validPeriod: "Validity Period",
          validPeriodTnc:
            "Only receipts dated 1st May 2024 onwards are accepted. Receipts must be uploaded within 30 days of the purchase date to qualify for point reward.",
          fraudPrevent: "Fraud Prevention",
          fraudPreventTnc:
            "Vinda holds absolute rights to block, disqualify and forteiture of all accured points, if any attempt to manipulate receipts or engage in fraudulent activity is detected.",
          duplicateTnc:
            "Duplicate receipt will be rejected. Please make sure, receipts are unique and not repeated.",
          vindaTnc:
            "Vinda Malaysia, in its absolute discretion, reserves the right to modify the rules and conditions without prior notice, if required for the benefits of all Club members.",
          clickHereFor: "Click here for",
          imageAndPoints: "Product Image & Points",
          supportedFormatTitle: "Supported image format",
          supportedFormatTnc: "JPEG, JPG, PNG only, not more than 10MB.",
          receiptDateError:
            "Only receipts dated 1st May 2024 onwards are accepted",
          failed: "Failed",
          submitAgain: "Submit Again",
          mysteryBoxZeroCountDescription:
            "Register your UC or upload Baby Care receipts to receive Mystery Box and qualify for an entry into the Weekly Prize and Grand Prize",
          mysteryBoxWarning1Week:
            "We noticed that you haven’t unboxed your mystery box yet. Don’t miss out on your exciting rewards!",
          youHave: "You have ",
          mysteryBoxTobeOpened: " Mystery Box(es) to be opened",
          mysteryHomeTitleDesc:
            "Get rewarded with Mystery Box and stand an entry into the weekly prizes and grand prize by uploading receipts and/or register your Unique Code",
          tncWord: "Terms & Conditions applied",
          entryEarned: "Entry Earned",
          forGrandPrize: "For Weekly & Grand Prize",
          prize: "Prize",
          howToWin: "How To Win",
          keyInUniqueCode: "Key in Unique Code",
          uploadBabyCare: "Upload Baby Care Receipt",
          myBoxOpen: "Mystery Box to open ",
          myBox: "boxes ",
          youHaveOpened: "You have opened ",
          myBoxGrandPrize: "GRAND PRIZE",
          FiveWinnerWin: "5 WINNERS WIN WEEKLY PRIZES!",
          myBoxAndMore: "...AND MORE ",
          mysteryBox: " MYSTERY BOX ",
          prizeToReveal: " PRIZES TO REVEAL!!",
          aeonVoucher: "AEON Voucher RM50",
          petronasGiftCard: "Petronas Gift Card RM50",
          submission: "Submission",
          howToWin1: "Purchase Drypers and/or Baby Care Products ",
          howToWin2:
            "Register Your Unique Code (UC) and/or  Upload Baby Care Receipt",
          howToWin2Part2:
            "Submissions of Baby Care receipts are subject to validation afer upload (within 3-5 working days). Only approved submissions will entitle to rewards and entries after successful validation.",
          howToWin3: "For each submission, you’ll receive:",
          howToWin4:
            "Check Drypers Malaysia Facebook for the Winners Announcements. The weekly prize winners will be announced on a weekly basis, while the grand prize winner will be announced after the campaign ends.",
          howToWinPrize1: "Mystery Box",
          howToWinPrize2: "Weekly Prize Entry ",
          howToWinPrize3: "Grand Prize Entry ",
          openAnotherBox: "Open Another Box",
          viewMyReward: "View My Rewards",
          uploadReceiptMyBoxMessage:
            "Once approved, you will able to unlock your Mystery Box.",
          scanCodeMyBoxMessage:
            "Congratulations! You have received a Mystery Box.",
          newSucc: "Receipt uploaded successfully!",
          newUploadSucc:
            "The receipt  will be validated within 3-5 working days.",
          newRegistered: "Unique Code Accepted",
          youHaveReceived: "You have gained ",
          smallPoints: " points!",
          tng: "TnG Reload Pin",
          aeon: "AEON eVoucher",
          vinda: "Vindamall eVoucher",
          beforeEmail:
            "You will receive the e-Gift AEON voucher in your iAEON App (For iAEON App User), OR an email from AEON (For Non-iAEON App User) based on your ​",
          theEmail: "DBC email",
          afterEmail: " within 5 working days.",
          gifConfirm: "REGISTER CODE NOW",
          gifCancel: "BACK TO HOME",
          scan3xTitle1: "You have gained ",
          scan3xTitle2: "Points!",
          scan3xDescription1: "You are rewarded with",
          scan3xDescription2: "3X Points",
          scan5xDescription2: "RM5 Vindamall eVoucher",
          scan10xDescription2: "RM10 Vindamall eVoucher",
          scan3xDescription3: " for registering your",
          scan3xDescription4: " 1st Drypers Pant's Diaper Unique Code.",
          scan5xDescription4: " 5th Drypers Pant's Diaper Unique Code.",
          scan10xDescription4: " 10th Drypers Pant's Diaper Unique Code.",
          scan3xDescription5: "",
          scan3xDescription6: "Redeem your rewards now at the",
          scan3xButton: "OKAY",
          scan3XPointShop: "Point Shop!",
        },
      },
      bm: {
        translation: {
          welcome: "Selamat Kembali",
          goldlevel: "Gold level",
          yourpoints: "MATA GANJARAN ANDA",
          keyinunique: "DAFTAR KOD UNIK",
          scanuniquecode: "Daftar Kod Unik",
          myQuest: "Misi Saya",
          redemptionhistory: "Sejarah penebusan",
          myrewards: "PUSAT GANJARAN",
          latestpromo: "PROMOSI TERKINI EKSKLUSIF UNTUK ANDA",
          withyourpoints: "TEBUS DENGAN MATA GANJARAN",
          seemore: "LIHAT LAGI",
          seemoreprocap: "Lihat selanjurnya",
          shoponline: "Beli online sekarang, untuk penghantaran ke rumah",
          moregoodies: "LEBIH BANYAK GANJARAN UNTUK ANDA",
          vindamall: "Vindamall",
          lazada: "Lazada",
          shopee: "Shopee",
          scancode: "Imbas kod unik",
          scancodecap: "DAFTAR KOD UNIK",
          inbox: "PETI MESEJ",
          profile: "PROFIL",
          home: "Laman utama",
          signinto: "Log masuk untuk melihat kedudukan keahlian semasa",
          signin: "Log masuk",
          myquest: "MISI SAYA",
          dbcforum: "FORUM DBC",
          freesample: "SAMPEL PERCUMA",
          freesamplelow: "Sampel Percuma",
          helpcenter: "PUSAT BANTUAN",
          pointshop: "POINT SHOP",
          sharealittle: "Berkongsi keselesaan",
          babyproducts: "Produk bayi",
          lifestyle: "Gaya hidup",
          educational: "Pendidikan",
          points: " Mata Ganjaran",
          getfree: "DAPATKAN SAMPEL PERCUMA",
          referfriendsandbe: "Rujuk rakan dan dapat ganjaran",
          quest: "Misi",
          waysto: "Cara Kumpul Lebih Banyak Mata Ganjaran",
          needassist: "Perlukan bantuan",
          howtoredeempoints: "Bagaimana cara tebus mata ganjaran",
          whatrewards: "Saya Ingin Mencuba Sampel",
          ourstory: "Mengenai Kami",
          privacy: "Dasar Privasi",
          contactus: "Hubungi Kami",
          terms: "Terma",
          condition: "Syarat",
          useofcook: "Penggunaan Kuki",
          allrights: "HAK CIPTA TERPELIHARA DRYPERS MALAYSIA 2021",
          products: "Produk",
          sustainable: "Kelestarian",
          dbc: "Drypers Baby Club",
          latesthappen: "INFO TERKINI",
          parentingtip: "Tips keibubapaan",
          shop: "Kedai",
          settings: "Tetapan",
          logout: "Log Keluar",
          logoutcap: "LOG KELUAR",
          login: "LOG MASUK",
          setdigits: "Tetapkan 10 digit kod unik dalam zon pengimbasan",
          keyin: "Masukkan kod",
          registercode: "Daftar Kod Unik",
          youcanalso:
            "Untuk mengelakkan kesilapan, sila pastikan anda hanya klik sekali selepas memasukkan kod",
          theuc: " kod unik secara manual",
          submit: "HANTAR",
          registered: "Kod disahkan",
          youhaveregistered: "Anda telah berjaya mendaftar kod unik anda",
          youmayredeem: "kini anda boleh menebus hadiah menarik ",
          pointshoplow: "di Pointshop anda",
          orderhistory: "REKOD PESANAN",
          myvoucher: "BAUCAR SAYA",
          mymysterybox: "KOTAK MISTERI",
          current: "Semasa",
          history: "Sejarah",
          redeemnow: "Tebus sekarang",
          redeemnowcap: "TEBUS SEKARANG",
          donateNow: "DERMA SEKARANG",
          termsandcon: "Terma dan syarat",
          delivery: "Butiran penghantaran",
          fillinaddress: "Isikan alamat penghantaran baharu anda",
          name: "Nama",
          email: "E-mel",
          fullname: "Nama Penuh",
          dob: "Tarikh Lahir",
          edb: "Jangkaan Tarikh Kelahiran",
          password: "Kata Laluan",
          contactdetails: "Butiran Kenalan",
          phonenumber: "Nombor Telefon",
          emailaddr: "Alamat Emel",
          address: "Alamat",
          addrline1: "Alamat Baris 1",
          addrline2: "Alamat Baris 2",
          streetname: "Nama Jalan",
          city: "Bandar",
          state: "Negeri",
          postcode: "Poskod",
          country: "Negara",
          work: "Pejabat",
          others: "Lain-lain",
          addaddress: "Kemaskini Alamat",
          savethisaddress: "Simpan alamat ini?",
          areyousure:
            "Adakah anda pasti mahu menyimpan alamat ini? Anda sentiasa boleh mengemaskini alamat anda kemudian.",
          save: "Simpan",
          edit: "Kemaskini",
          chooseaddress: "Pilih alamat penghantaran anda",
          confirm: "Sahkan",
          additem: "Tambah Item",
          myredemption: "Jumlah mata ganjaran",
          mypoint: "Baki selepas tebus",
          trackorder: "JEJAK PESANAN ANDA",
          orderplaced: "Pesanan Diletakkan",
          youhavemade: "Anda telah membuat pesanan anda pada",
          wearepreparing: "Kami sedang menyediakan pesanan anda",
          orderplace: "Pesanan disahkan",
          orderprocessed: "Pesanan diproses",
          preparetoship: "Persediaan untuk penghantaran",
          orderhasbeen: "Pesanan telah dihantar",
          orderreceived: "Pesanan diterima",
          activateaccount: "Aktifkan akaun anda untuk teruskan",
          scantoactivate: "Imbas kod produk unit untuk mengaktifkan akaun anda",
          maybelater: "Mungkin kemudian",
          signintocontinue: "Log masuk untuk teruskan",
          signintodrypers:
            "Log masuk ke dalam akaun drypers baby club untuk tebus ganjaran",
          signintoreview:
            "Log masuk untuk melihat ganjaran semasa anda daripada drypers baby club",
          redeemrewards: "Tebus ganjaran",
          areyousuretoredeem:
            "Adakah anda pasti mahu menebus ganjaran ini ? Mata ganjaran anda akan ditolak setelah pengesahan butiran dilakukan",
          vouchernum: "Nombor baucar",
          howtouse: "CARA UNTUK GUNA",
          pointshistory: "Sejarah Mata Ganjaran",
          pointsearned: "Mata Diperoleh",
          pointsused: "Mata Ganjaran Digunakan",
          tierbenefits: "Faedah kedudukan",
          latestreward: "Ganjaran terkini",
          collectpoints: "Cara kumpul mata ganjaran",
          completequest: "Selesaikan misi",
          account: "Akaun",
          editprofile: "Kemaskini profil",
          personalinfo: "Maklumat Peribadi",
          rewards: "Ganjaran",
          refernearn: "Rujuk Kawan & Dapat Ganjaran",
          help: "Soalan-soalan Lazim",
          race: "Kaum",
          gender: "Jantina",
          male: "Lelaki",
          female: "Perempuan",
          nationality: "Kewarganegaraan",
          babyinfo: "Maklumat Bayi",
          myaddress: "Alamat Saya",
          addchild: "Tambah Maklumat Bayi",
          currentstage: "Peringkat semasa anda",
          childbirthday: "Hari lahir anak",
          childgender: "Jantina Anak",
          sharelist: "Senarai kongsi",
          babydiaper: "Jenama Lampin Bayi",
          babyproduct: "Produk Bayi",
          diaperformat: "Format Lampin Bayi",
          diapersize: "Saiz Lampin Bayi",
          refernow: "Rujuk rakan anda sekarang dan dapatkan 500 mata ganjaran",
          refernowPlatinum:
            "Rujuk rakan anda sekarang dan dapatkan 550 mata ganjaran",
          refernowDiamond:
            "Rujuk rakan anda sekarang dan dapatkan 600 mata ganjaran",
          copycode: "Salin kod rujukan",
          receivecurrentupdate:
            "Log masuk untuk menerima kemas kini semasa daripada drypers baby club",
          search: "Cari",
          faq: "Soalan lazim",
          browseby: "Lihat mengikut topik",
          rewardsredemp: "Penebusan ganjaran",
          newquest: "Misi baru dibuka",
          letscelebrate: "Jom sambut 64 tahun merdeka",
          learnmore: "Ketahui lebih lanjut",
          forum: "Forum",
          recommended: "Disyorkan",
          wouldyoulike: "Mahukah anda cuba sampel percuma?",
          yes: "Ya",
          no: "Tidak",
          yescap: "YA",
          nocap: "TIDAK",
          maybecap: "BUKAN SEKARANG",
          nameerror: "Sila elakkan menggunakan aksara khas",
          numbererror: "Anda telah memasukkan nombor telefon yang tidak sah",
          emailerror: "Anda memasuki e-mel yang tidak sah",
          selectsize: "Pilih Saiz",
          selectformat: "Pilih Format",
          noavailableformat: "Tiada format tersedia",
          noavailablesampleswith:
            "Tiada sampel tersedia dengan saiz dan format ini",
          pleaseselect: "sila pilih saiz dan format lain",
          noavailablesamples: "Tiada sampel tersedia",
          register: "Daftar",
          signupasmember:
            "Daftar sebagai ahli dan nikmati pelbagai faedah, promosi, dan hadiah percuma",
          signupbelow: "Atau daftar di bawah",
          noofchild: "Bilangan Kanak-kanak",
          number: "Nombor",
          dobchild: "Tarikh Lahir Anak",
          referralcode: "Masukkan Kod Rujukan (jika ada)",
          existingmember: "Sudah Mendaftar?",
          logincap: "LOG MASUK",
          registercap: "DAFTAR",
          error: "Ralat",
          notRegistered:
            "Ahli ini tidak didaftar. Sile ke muka Pendaftaran Ahli.",
          forgetpassword: "Lupa kata laluan?",
          orconnectusing: "Atau log masuk menggunakan",
          donthaveaccount: "Tiada akaun?",
          signup: "DAFTAR",
          ourstorycap: "TENTANG KAMI",
          aboutuscap: "MENGENAI DRYPERS",
          productscap: "PRODUK",
          sustaincap: "KELESTARIAN",
          dbccap: "DRYPERS BABY CLUB",
          latesthapcap: "INFO TERKINI",
          parentingcap: "TIPS KEIBUBAPAAN",
          FAQcap: "FAQ",
          shopcap: "BELI SEKARANG",
          neverbe: "Jangan ke-mana-mana tanpa Drypers!",
          getfreecap: "SAMPEL PERCUMA",
          samplescap: "PERCUMA",
          refercap: "RUJUK KAWAN",
          berewardcap: "DAPAT GANJARAN",
          questcap: "MISI",
          waystocap: "CARA MENGUMPUL MATA GANJARAN",
          collectpointscap: "KUMPUL MATA GANJARAN",
          atleastten: "Kod mestilah sekurang-kurangnya 10 digit.",
          exploredbc: "Terokai Drypers Baby Club",
          stayconnected: "Kekal berhubung dengan kami",
          all: "Semua",
          evouchers: "E-Baucar",
          pregnantOffer: "Tawaran Hamil",
          orderid: "ID Pesanan",
          prodpurc: "Produk Dibeli",
          pts: " mata ganjaran",
          orderdate: "Tarikh Pesanan",
          thisitem:
            "Barang ini tidak tersedia untuk penebusan, sila pilih item lain",
          outofstock: "Kehabisan stok",
          usernotfound:
            "Pengguna tidak ditemui, sila muat semula dan cuba lagi",
          insuffpoints: "Mata Ganjaran tidak mencukupi",
          insuffipoints:
            "Harap maaf Mata Ganjaran anda tidak mencukupi untuk meneruskan transaksi ini. Terima Kasih.",
          validuntil: "Sah sehingga",
          applicable: "eBaucar sah untuk",
          allprod: "SEMUA produk",
          purchat: "yang dibeli di",
          eachvouc:
            "Setiap eBaucar sah untuk satu (1) transaksi, pada (1) masa penggunaan sahaja.",
          nonreturn:
            "Tidak boleh dikembalikan, tidak boleh ditukar-ganti, dan tarikh penebusan tidak boleh dilanjutkan",
          freeshiip:
            "Penghantaran PERCUMA untuk pembelian melebihi RM50 selepas diskaun",
          moreinps: "Lagi di Pointshop",
          delivaddr: "ALAMAT PENGHANTARAN",
          getyourdrypers: "Dapatkan sampel percuma Drypers anda hari ini!",
          sorry: "Maaf",
          trynow: "Cuba Sekarang",
          youalreadyre:
            "Anda telah pun menebus sampel percuma anda. Anda boleh menebusnya semula dalam masa 3 bulan",
          yourfeedback: "Maklum Balas Anda Amat Dihargai",
          itseems:
            "Kongsikan maklum balas anda berkenaan pek sampel yang telah diterima dan dapatkan 50 mata ganjaran!",
          sharefeedback: "Kongsi Maklum Balas",
          haveyoureceived: "Sudahkah Anda Menerima Sampel Percuma?",
          doyouhaveany:
            "Adakah anda mempunyai sebarang komen yang ingin anda kongsikan kepada kami?",
          continue: "SETERUSNYA",
          tq: "Terima Kasih!",
          tqPopup:
            "Terima kasih mendaftar kepada Drypers Baby Club, kita telah menghantar e-mel pengesahan kepada alamat e-mel anda.",
          wehavereceive: "Kami telah menerima maklum balas anda!",
          wehavereceive2:
            "Terima kasih atas maklum balas anda. Mata ganjaran anda telah dikreditkan!",
          tqlocation:
            "Terima kasih atas masa anda dan kami harap anda menikmati sampel percuma anda!",
          tqnotlocation:
            "Terima kasih atas masa anda untuk berkongsi maklum balas anda dengan kami",
          confirmorder: "Sahkan Pesanan?",
          areyouorder:
            "Adakah anda pasti mahu mengesahkan penempatan pesanan anda?",
          requestsent: "Permintaan Sampel Percuma Berjaya Dihantar!",
          yoursent: "Permintaan sampel percuma anda telah dihantar.",
          receivesample:
            "Anda akan menerima sampel percuma pilihan anda dalam masa 7 hari.",
          cancel: "Batal",
          totalredeem: "Jumlah Mata Ganjaran Ditebus",
          mybalpts: "Baki Mata Ganjaran Saya",
          inboxlow: "Peti Mesej",
          clearall: "Kosongkan Semua",
          letcelebrate: "Mari Sambut 64 Tahun Merdeka",
          uploadpicture: "Muat naik Gambar Untuk Menyertai Peraduan",
          label: "Label",
          labellong: "Label (cth. Rumah, Pejabat)",
          houseno: "Rumah No.",
          stage: "Pentas",
          trying: "Kami sedang cuba untuk bayi",
          pregnant: "Saya hamil / pasangan saya hamil",
          oneChild: "Saya mempunyai satu anak",
          moreChild: "Saya mempunyai lebih dari satu anak",
          date: "Tarikh",
          brand: "Jenama",
          format: "Format",
          size: "Saiz",
          done: "SELESAI",
          babysaved: "Maklumat bayi berjaya disimpan",
          back: "KEMBALI",
          emailaddress: "Alamat E-mel",
          usemyrefer:
            "Gunakan kod rujukan saya untuk mendaftar ke program ini.",
          share: "KONGSI",
          firstcuddle: "Sejuta Pelukan Pertama Bermula Di Sini",
          babyfirstcry:
            "Dari tangisan pertama bayi anda hingga langkah pertama bayi anda. Drypers Baby Club hadir untuk membesarkan generasi akan datang bersama anda - disertai dengan banyak pelukan di antaranya! Kelab kami menjadikan keibubapaan lebih menyeronokkan dengan memberi ganjaran percuma dan hadiah kepada anda, serta promosi menarik sepanjang perjalanan. Daftar sekarang untuk dunia yang penuh dengan faedah dan keistimewaan!",
          becauseparent: "KERANA SETIAP IBU BAPA & BAYI LAYAK DAPAT GANJARAN",
          welcomegift: "HADIAH ALUAN*",
          freegoodies: "Hadiah percuma untuk anda dan bayi",
          asanewmember:
            "Sebagai ahli yang baharu anda berpeluang untuk mendapatkan hadiah aluan! Selamat datang!",
          freesamples: "SAMPEL PERCUMA",
          bethefirst: "Jadi yang pertama untuk menerima sampel percuma!",
          getfirstdips:
            "Lebih mudah untuk menentukan lampin yang sesuai untuk anak anda. Sebagai ahli anda boleh mendapatkan sampel percuma!",
          redeempoints: "TEBUS MATA GANJARAN UNTUK HADIAH MENARIK",
          turnpoints: "Terima hadiah menarik menggunakan mata ganjaran",
          eachtime:
            "Setiap pembelian lampin anda, ada ganjarannya. Anda boleh mengumpul mata ganjaran dan menebus pelbagai hadiah menarik di Pointshop!",
          pregnancy: "PANDUAN BERKAITAN KEHAMILAN DAN KEIBUBAPAAN",
          babybumbum: "Kenali dunia bayi anda, di setiap peringkat tumbesaran",
          whetherplan:
            "Sama ada anda sedang merancang, menjangkakan kelahiran bayi atau bersama bayi baru lahir serta anak yang membesar, semua jenis panduan tersedia untuk anda. Kami sentiasa bersama anda dalam perjalanan keibubapaan ini!",
          apply: "Memohon",
          diapertype: "Jenis Lampin",
          fbsignup: "Daftar Dengan Facebook",
          googsignup: "Daftar Dengan Google",
          mobile: "Telefon",
          producttype: "Jenis Produk",
          readourprivacy:
            "Sila baca Dasar Privasi dan Syarat penggunaan kami.*",
          ihaveread: "Saya telah membaca dan memahami",
          infoonper: " maklumat mengenai data peribadi",
          iagreewith: "dan saya bersetuju dengan",
          termsofuse: "syarat penggunaan",
          yesiliketo:
            "Ya, saya ingin menerima maklumat daripada Drypers pada masa hadapan",
          loginexisting: "Log Masuk",
          drysamplereq: "Sampel Percuma Drypers",
          offerschild:
            "Drypers menawarkan anak anda keselesaan dan fleksibiliti yang terbaik.",
          tryiton:
            "Ia sesuai untuk kegunaan siang dan malam. Cuba pada anak anda untuk melihat perbezaan yang boleh dilakukan oleh Drypers.",
          onlyonesample:
            "Permintaan sampel daripada isi rumah yang sama dalam tempoh 3 bulan tidak akan diterima.",
          additional:
            "Permintaan sampel untuk isi rumah yang sama dengan alamat berlainan tidak akan diterima.",
          multiple:
            "Permintaan sampel untuk bayi yang sama menggunakan berbilang alamat tidak akan dilayan.",
          validaddress:
            "Sah untuk ahli yang mempunyai alamat pos Malaysia sahaja.",
          incompleteentries:
            "Penyertaan yang tidak lengkap tidak akan diproses.",
          timetaken:
            "Masa yang diambil untuk penghantaran sampel adalah antara 2 - 3 minggu. Sampel dihantar menggunakan perkhidmatan pos biasa.",
          righttopostpone:
            "Vinda Marketing (M) Sdn Bhd mempunyai hak untuk menangguhkan atau membatalkan program ini tanpa sebarang notis awal.",
          quests: "Pencarian",
          findouthowcan:
            "Saya Telah Mendaftar Tetapi Masih Belum Menerima E-Mel Pengaktifan",
          dryperforum: "Forum Drypers Baby Club",
          scanproductcode: "IMBAS KOD PRODUK",
          scanproductcodeDesktop: "IMBAS KOD UNIK",
          doquests: "BUAT MISI",
          referfriends: "RUJUK KAWAN",
          pointscap: "MATA GANJARAN",
          prodcampclub: "Produk, Kempen, Kelab",
          proreleemail: "Pertanyaan Produk",
          campreleemail: "Pertanyaan Drypers Baby Club",
          clubreleemail: "Pertanyaan Promo/Kempen",
          foranyfurther: "Hubungi kami di talian 1-800-88-9988 ",
          // oremailat: "atau e-mel kami di",
          chatbot: "Chat Bersama Kami",
          // integratewih: "(integrasi dengan Yellow Messenger)",
          // time: "(Isnin – Jumaat, 9am – 5.30pm)",
          signupnow: "DAFTAR SEKARANG",
          waystotitle1: "Daftar Kod Unik",
          waystotitle2: "SELESAIKAN MISI",
          waystotitle3: "KENALKAN RAKAN ANDA",
          waysto1:
            "Kod Unik merupakan bukti sah pembelian yang diperlukan untuk pengumpulan mata ganjaran.",
          waysto2:
            "Lokasi Kod Unik di pek Drypers Touch, Drypers Wee Wee Dry, Drypers DryPantz & Drypers Classic ialah di bahagian dalam polibeg.",
          waysto3:
            "Dalam pek Drypers DryPantz, anda akan menemuinya di bahagian dalam pek di bawah penunjuk Berat/Saiz, hanya di sebelah kawasan kod bar.",
          waysto4:
            "Lokasi Kod Unik bagi pek Skinature by Drypers pula terletak di bahagian atas pek, seperti dalam gambar di bawah:",
          waysto5: "Terima mata ganjaran berdasarkan",
          waysto6: "Jadual Pek & Mata Ganjaran",
          waysto7: "Dapatkan Mata Ganjaran tambahan dengan melengkapkan misi",
          waysto8: "Klik di bahagian misi",
          waysto9: "Pilih misi yang ingin diselesaikan",
          waysto10: "Lengkapkan misi tersebut",
          waysto11: "Dapatkan Mata Ganjaran selepas penyelesaian misi",
          waysto12:
            "Kenalkan rakan atau kenalan anda untuk menyertai Drypers Baby Club dan raih 500 mata ganjaran!",
          waysto13: "Rujuk rakan dalam 3 langkah mudah:",
          waysto14: "Klik pada Profil, Pilih Rujuk Kawan & Dapatkan Ganjaran",
          waysto15: "Kongsi Kod Rujukan anda kepada rakan anda",
          waysto16: "Raih 500 Mata Ganjaran untuk setiap rakan yang dirujuk",
          fptext:
            "Masukkan alamat e-mel yang anda daftarkan, dan anda akan menerima pautan untuk menetapkan semula kata laluan anda.",
          fp: "Terlupa kata laluan",
          senta: "Kami telah menghantar kata laluan baharu kepada",
          junk: 'Jika anda belum menerimanya dalam peti masuk anda, semak dalam "spam" atau "mel sampah"',
          return: "Kembali ke halaman log masuk",
          time: "(Isnin - Jumaat, 9am - 5.30pm)",
          resetTitle: "SET SEMULA KATA LALUAN",
          enternewpwd: "Kata Laluan Baharu",
          confirmnewpwd: "Pengesahan Kata Laluan",
          resetDescription:
            "Laman web kami kini telah diperbaharui untuk pengalaman lebih baik buat anda. Untuk tujuan keselamatan akaun sila set semula kata laluan anda.",
          weSent: "Semak inbox",
          checkSpam:
            "dan klik pada pautan yang diberi untuk set semula kata laluan anda.",
          comingSoon:
            "Bahagian Drypers Baby Club sedang menjalani proses penyelengaraan laman web untuk memberi perkhidmatan yang lebih baik kepada anda! Kami akan kembali pada 17hb Mac, jam 11.00 pagi.\n\nJika anda tidak dapat mendaftarkan kod unik anda atau memperolehi mata ganjaran berganda, jangan risau, anda berpeluang untuk meraih mata ganjaran berganda sekali lagi pada 22hb Mac!\n\nUntuk pertanyaan, sila hubungi kami di talian bebas tol 1-800-88-9988 atau e-mel kami di my.drypersbabyclub@vinda.com",
          wrongpwd: "Anda memasuki kata laluan yang salah",
          updatemypwd: "Set kata laluan",
          sendmemypwd: "Hantar kata laluan saya",
          succ: "Berjaya",
          successresetmsg:
            "Anda berjaya menetapkan semula kata laluan anda. Sila log masuk menggunakan kata laluan baharu anda.",
          returntologin: "Kembali ke halaman log masuk",
          helptitle1:
            "Bagaimanakah cara saya mengumpul mata ganjaran daripada Drypers Baby Club?",
          helptitle2: "Bagaimana untuk Menebus Produk Bayi?",
          helptitle3: "Bagaimana untuk Menebus eBaucar?",
          helptitle4: "Bagaimana untuk Memohon Sampel?",
          helptitle5: "PANDUAN",
          help1:
            "Anda boleh memperolehi mata ganjaran selagi anda berstatus ahli Drypers Baby Club. Keahlian anda dalam Drypers Baby Club kekal sehingga anak anda berumur lima tahun.",
          help2: "Anda boleh mengumpul mata ganjaran dengan:",
          help3: "Membeli pek lampin Drypers terpilih",
          help4:
            "Menjawab atau menerima mata ganjaran dalam soal selidik e-mel",
          help5: "Merujuk rakan untuk menjadi ahli Drypers Baby Club",
          help6:
            "Mengambil bahagian dalam promosi tertentu yang sedang dijalankan oleh Drypers Baby Club",
          help7:
            "Sertai sekarang dan mulakan perjalanan anda sebagai ahli Drypers Baby Club!",
          tnca: "*Tertakluk kepada Terma & Syarat",
          help8:
            "Anda boleh menebus Produk Bayi di Pointshop menggunakan mata ganjaran yang telah anda kumpul.",
          help9: "Langkah-langkah:",
          help10: "Pergi ke Pointshop melalui Laman Utama.",
          help11:
            "Tekan butang Produk Bayi yang terletak di dalam katalog Pointshop.",
          help12: "Pilih Produk Bayi yang anda hendak ditebuskan.",
          help13: "Tekan butang “Tebus Sekarang”.",
          help14: "Tekan butang “Sahkan”.",
          help15:
            "Anda boleh menebus eBaucar di Pointshop menggunakan mata ganjaran yang telah anda kumpul.",
          help16: "Pergi ke Pointshop melalui Laman Utama.",
          help17:
            "Tekan butang E-Baucar yang terletak di dalam katalog Pointshop.",
          help18: "Pilih eBaucar yang anda hendak ditebuskan.",
          help19: "Tekan butang “Tebus Sekarang”.",
          help20:
            "Untuk mencuba sampel percuma, anda perlu mendaftar sebagai ahli Drypers Baby Club. Pendaftaran adalah percuma dan menjadi ahli Drypers melayakkan anda mendapat semua faedah kelab.",
          help21: "Pergi ke https://www.drypers.com.my/drypers-sample-request/",
          help22: "Pilih sampel dan saiz percuma anda.",
          help23:
            "Log masuk ke akaun anda / jika anda belum mendapat akaun, daftar sebagai ahli.",
          help24: "Sahkan alamat penghantaran anda.",
          help25:
            "Permintaan Sampel Percuma akan dihantar ke alamat penghantaran anda, dan anda akan menerima sampel percuma pilihan anda dalam 4-6 minggu.",
          help26: "Apakah Ganjaran yang saya layak terima?",
          help27:
            "Cari senarai ganjaran di Points Shop, ia akan memaparkan semua ganjaran yang anda layak terima.",
          help28: "Bagaimanakah saya boleh mendapatkan Sampel Percuma?",
          help29:
            "Klik pada SAMPEL PERCUMA, senarai produk boleh leret, pilih produk yang anda inginkan, pilih saiz dan anda akan diarahkan ke halaman penerangan produk, klik DAPATKAN SAMPEL PERCUMA dan sahkan alamat penghantaran anda. Permintaan Sampel Percuma akan dihantar dan anda akan menerima sampel percuma pilihan anda dalam 4-6 minggu untuk penghantaran.",
          help30: "Bagaimanakah saya layak mendapat sampel percuma?",
          help31:
            "Hanya satu permintaan sampel bagi setiap bayi bagi setiap isi rumah dibenarkan dengan sejumlah 4 permintaan sampel dalam tempoh 2 tahun. Permintaan sampel tambahan dari isi rumah yang sama untuk bayi yang sama dalam tempoh 3 bulan tidak akan dilayan. Permintaan sampel untuk bayi yang sama menggunakan berbilang alamat tidak akan dilayan. Sah untuk ahli yang mempunyai alamat pos Malaysia sahaja. Penyertaan yang tidak lengkap tidak akan diproses. Vinda Marketing (M) Sdn Bhd mempunyai hak untuk menangguhkan atau membatalkan program ini tanpa sebarang notis terlebih dahulu.",
          help32:
            "Bagaimanakah saya harus memberikan Maklum Balas berkenaan Sampel Percuma selepas saya menerima?",
          help33:
            "14 hari selepas anda menyerahkan permintaan sampel percuma anda, anda akan dapati ini di bahagian Sampel Percuma, sila kongsi komen dengan kami.",
          help34:
            "Bagaimanakah saya boleh mendapatkan maklumat tentang promosi terkini?",
          help35:
            "Klik PETI MESEJ di bahagian bawah halaman dan klik pada satu reben mesej untuk mengetahui lebih banyak maklumat tentang mesej tawaran.",
          help36: "Bagaimanakah saya boleh menghubungi pasukan sokongan?",
          help37:
            "Scroll ke bawah Halaman Utama atau Klik Help Center apabila anda memerlukan bantuan, klik pada bahagian FAQ di Halaman Utama. Hubungi kami jika pertanyaan anda tidak dijawab di bahagian Soalan Lazim, anda boleh menghantar e-mel kepada kami di HUBUNGI KAMI, isikan pertanyaan anda dan kami akan menghubungi anda semula secepat mungkin.",
          helptitle6: "Soalan Lazim Single Sign-On:",
          helpsso:
            "Saya mempunyai akaun di Drypers Baby Club dan Vindamall, alamat e-mel manakah yang harus saya gunakan untuk log masuk?",
          helpsso2:
            "Jika alamat e-mail di Drypers Baby Club dan Vindamall adalah sama, anda boleh menggunakan gabungan alamat e-mel dan kata laluan daripada mana-mana akaun tersebut",
          helpsso3: "Bagaimana jika saya tidak mempunyai akaun Vindamall? ",
          helpsso4:
            "Anda boleh log masuk dengan alamat e-mel dan kata laluan Drypers Baby Club anda. ",
          helpsso5:
            "Bagaimana jika akaun Vindamall saya mempunyai alamat e-mel yang berbeza daripada akaun Drypers Baby Club saya? ",
          helpsso6:
            "Akaun Drypers Baby Club yang baharu akan dicipta apabila anda log masuk buat kali pertama ke Drypers Baby Club menggunakan alamat emel dan kata laluan Vindamall.  ",
          helpsso7:
            "Berdasarkan 3 soalan di atas, ini bermakna saya akan mempunyai 2 akaun Drypers Baby Club. Akaun Drypers Baby Club manakah yang patut saya gunakan? ",
          helpsso8:
            "Untuk meneruskan kemajuan anda di Drypers Baby Club, anda boleh log masuk dengan akaun Drypers Baby Club anda yang sedia ada. ",
          helpsso9:
            "Jika saya memilih untuk meneruskan akaun Drypers Baby Club saya yang sedia ada, apakah yang akan berlaku kepada akaun Drypers Baby Club baharu yang dicipta? ",
          helpsso10:
            "Ia akan kekal sebagai akaun baharu bermula dengan 0 mata ganjaran, dan akan dipautkan ke akaun Vindamall anda. ",
          helpsso11:
            "Mengapakah saya perlu menetapkan semula kata laluan saya?",
          helpsso12:
            "Anda akan diminta untuk menetapkan semula kata laluan anda untuk mengukuhkannya mengikut keperluan kata laluan terkini. ",
          helpsso13:
            "Adakah penetapan semula kata laluan akan menyebabkan sebarang perubahan ke atas mata ganjaran Drypers Baby Club saya? ",
          helpsso14:
            "Tidak perlu risau, mata ganjaran Drypers Baby Club anda tidak akan terjejas.",
          helpsso15: "Apakah format kata laluan baharu yang diperlukan?",
          helpsso16:
            "Kata laluan mesti mengandungi sekurang-kurangnya 8 aksara, 1 huruf besar, 1 huruf kecil, 1 digit, dan 1 aksara khas (@$!%*?&). ",
          helpsso17:
            "Apakah yang berlaku kepada sejarah mata ganjaran dan status peringkat keahlian? ",
          helpsso18:
            "Sejarah mata ganjaran dan peringkat keahlian tidak dipengaruhi oleh Single Sign-On. Jika anda mempunyai kedua-dua akaun Drypers Baby Club dan Vindamall yang didaftarkan dengan alamat e-mel yang berbeza, apabila anda log masuk ke Drypers Baby Club dengan alamat e-mel dan kata laluan Vindamall anda, akaun Drypers Baby Club baharu akan dicipta, bermula dengan 0 mata ganjaran.",
          helpsso19:
            "Apakah kelebihan log masuk ke Vindamall dan Drypers Baby Club dengan alamat e-mel yang sama? ",
          helpsso20:
            "Log masuk dengan mudah di Drypers Baby Club, mahupun Vindamall.",
          helpsso21:
            "Di manakah saya boleh mendapatkan maklumat lanjut tentang penggunaan alamat emel dan kata laluan yang sama untuk log masuk ke Drypers Baby Club & Vindamall? ",
          helpsso22: "Klik ",
          helpsso22b: "<Panduan Log Masuk Vindamall & Drypers Baby Club> ",
          helpsso22c: "untuk mendapatkan maklumat lanjut.",
          blurrycam: "Kamera kabur?",
          switch: "Tukar Kamera",
          nothing: "Tiada apa-apa di sini",
          uq: "Kod Unik",
          participatingdryper:
            "Pek Drypers Diaper yang Menyertai Untuk Kutipan Mata Ganjaran",
          helpinfo1:
            "Untuk mencuba sampel percuma, anda perlu mendaftar sebagai ahli Drypers Baby Club. Pendaftaran adalah percuma dan menjadi ahli Drypers melayakkan anda mendapat semua faedah kelab. Pergi ke https://www.drypers.com.my/drypers-sample-request/ . Pilih sampel dan saiz percuma anda, log masuk ke akaun anda, jika anda belum mendapat akaun, daftar sebagai ahli. Kemudian sahkan alamat penghantaran anda, dan Permintaan Sampel Percuma akan dihantar dan anda akan menerima sampel percuma pilihan anda dalam 4-6 minggu untuk penghantaran.",
          helpinfo2:
            "Semak peti masuk anda termasuk folder junk dan spam mail anda",
          helpinfo3:
            "Jika anda gagal menerima e-mel pengaktifan, Hubungi kami untuk mendapatkan bantuan lanjut daripada Pasukan Sokongan",
          desktopShare:
            "Jika anda tidak berjaya untuk kongsi kod rujuk ini menerusi laman ini, sila cuba menerusi telefon bimbit anda",
          questComingSoon: "Harap sabar menunggu cabaran yang akan datang!",
          pointshopChoice: "GANJARAN",
          maintenance:
            "Ahli yang dihormati, kami mengalami ketidakstabilan di bahagian Point Shop kami. Kami akan beroperasi semula secepat mungkin. Terima kasih atas kesabaran anda.",
          newAddress:
            "Ahli yang dihormati, untuk memastikan tiada sebarang kesulitan dalam penghantaran item penebusan/sampel, sila isikan alamat anda yang terkini. Terima kasih atas kerjasama anda.",
          pointMessage:
            "Ahli yang dihormati, kami memahami bahawa sebilangan daripada anda mengalami percanggahan baki mata ganjaran dalam akaun anda. Kami sedang memperbaiki isu ini dan bahagian pendaftaran kod unik ditangguhkan buat sementara waktu. Anda boleh mendaftarkan kod unik anda selepas 25 Mac. Terima kasih atas kesabaran anda.",
          yougained: "Anda telah dapat ",
          donateDescription:
            "Mata Ganjaran Drypers anda teramat bernilai kepada kami. Dermakan Mata Ganjaran Drypers untuk membaik pulih 4 rumah anak-anak yatim untuk menyediakan persekitaran yang lebih baik dan lebih cerah bagi mereka",
          confirmRedeemDesc:
            "Adakah anda pasti mahu menebus ganjaran ini? Mata ganjaran anda akan ditolak setelah pengesahan butiran dilakukan",
          fitAThon: "Fit-A-Thon",
          questsToCollect:
            "Selesaikan semua aktiviti untuk dapat hadiah HEBAT!",
          activityOneGrandPrize:
            "Hadiah Utama: Smart Watch\n + \n1000x hadiah saguhati",
          activityTwoGrandPrize:
            "Hadiah Utama: Smart Watch\n + \n1000x hadiah saguhati",
          activityThreeGrandPrize:
            "Hadiah Utama: Smart Watch\n + \n1000x hadiah saguhati",
          activityFourGrandPrize:
            "Hadiah Utama: Bekalan Drypers sepanjang hayat\n + \n1000x hadiah saguhati",
          activityOneDesc: "Menari dengan Drypers dan CoComelon",
          activityTwoDesc:
            "Main game AR dengan membantu JJ menangkap ciri lampin Drypers",
          activityThreeDesc: "Beli produk Drypers dan menang hadiah bertuah",
          activityFourDesc: "Tonton sesi Zoom live",
          activityOne: "Aktiviti 1",
          activityTwo: "Aktiviti 2",
          activityThree: "Aktiviti 3",
          activityFour: "Aktiviti 4",
          activityOnePeriod: "5 - 14 OGOS",
          activityTwoPeriod: "15 - 21 OGOS",
          activityThreePeriod: "22 - 27 OGOS",
          activityFourPeriod: "28 OGOS",
          activityOneTitle: "Aktiviti 1: Fit To Play",
          activityTwoTitle: "Aktiviti 2: Fit For Learning",
          activityThreeTitle: "Aktiviti 3: Fit To Discover",
          activityFourTitle: "Aktiviti 4: Siaran Langsung",
          activityOnePrizeToWin:
            "Hadiah untuk dimenangi: 1000 x Drypers edisi terhad x CoCoMelon T-Shirt (Untuk 1000 peserta pertama)",
          activityTwoPrizeToWin:
            "Hadiah Pertama 1x Jam Pintar!! Harga: 1000x Edisi terhad Drypers Beg Kanvas CoCoMelon!!! \n(Untuk 1000 peserta pertama)",
          activityThreePrizeToWin:
            "Hadiah Pertama: 1x Jam Tangan Pintar!! Hadiah 1000 x Drypers edisi terhad x Beg Kanvas CoCoMelon!!! \n(Untuk 1000 peserta pertama)",
          activityFourPrizeToWin:
            "Hadiah Utama: Bekalan Drypers sepanjang hayat* + 1 x beg cabin CoComelon + premium Edisi Terhad*\nHadiah Saguhati: 1000x Medal CoComelon Edisi Terhad",
          activityFourPrizeToWinSmall: "*Gambar untuk tujuan ilustrasi sahaja",
          stepToJoin: "Langkah untuk menyertai",
          stepToParticipate: "Langkah-langkah untuk sertai aktiviti",
          activityOneStepOne:
            "Cari lagu CoComelon* di Facebook Reels, Instagram Reels, atau TikTok dan rakam bayi anda menari.",
          activityOneStepOneSmall:
            "*Anda boleh guna mana-mana lagu CoCoMelon pada video anda.",
          activityOneStepTwo:
            "Kongsi post video anda dengan hashtag #DrypersFitAThon2, #DrypersMalaysia @DrypersMY",
          activityOneStepTwoSmall:
            "Pastikan anda screenshot post anda sebagai bukti penyertaan nanti.",
          activityOneStepThree: "Anda boleh menyerahkan maks.5 penyertaan",
          activityOneStepFour: "Hantar penyertaan anda di Aktiviti 1 sekarang!",
          activityTwoStepOne: "Main AR Filter Drypers FIT",
          activityTwoStepTwo:
            "Anda perlu cecah min. 1,000 point & kongsi video/gambar anda dengan hashtag #DrypersFitAThon2, #DrypersMalaysia @DrypersMY",
          activityTwoStepTwoSmall:
            "*Pastikan anda screenshot post anda sebagai bukti penyertaan nanti.",
          activityTwoStepThree: "Daftar",
          activityTwoStepThreePartTwo: " sekarang. Klik ikon",
          activityTwoStepThreePartThree:
            "Fit-A-Thon dan hantar screenshot skor tertinggi anda di Aktiviti 2!",
          activityThreeStepOne:
            "Beli produk Drypers min. RM30 dari 1 - 27 Ogos 2022 di Vindamall/Lazada/Shopee atau di mana-mana pasaraya berdekatan. Simpan resit sebagai bukti pembelian anda.",
          activityThreeStepTwo:
            "Cari angka SATU(1) dan KOSONG(0) di nombor resit atau nombor pesanan anda.",
          activityThreeStepTwoPartTwo:
            "untuk lihat contoh resit/no pesanan Vindamall/Lazada/Shopee. Resit/Invois pasaraya cth. AEON, Lotus's.",
          activityThreeStepThree:
            "Jika nombor resit atau nombor pesanan* anda mengandungi kedua-dua angka ini, hantar screenshot/gambar resit anda sebagai bukti penyertaan",
          activityThreeStepThreePartTwo: "",
          activityThreeStepThreeSmall:
            "*Setiap isi rumah layak untuk maksimum 5 penyertaan bagi setiap aktiviti.",
          activityThreeStepFour: "Anda boleh menyerahkan maks. 5 penyertaan",
          activityFourStepOne: "untuk menempah slot anda.",
          activityFourStepTwo:
            "Lihat penyertaan pautan ZOOM setelah melakukan tempahan.",
          activityFourStepThree:
            "Gunakan pautan ZOOM untuk menonton pusingan akhir secara langsung di ZOOM pada 8.30 malam, 28 Ogos 2022 dan anda berpeluang memenangi pelbagai hadiah menarik!",
          selectYourEntry: "Pilih Entri Anda",
          entryNote: "*NOTA: 1 PENYERTAAN = 100 MATA GANJARAN DIGUNAKAN*",
          entry: "Entri",
          joinNow: "SERTAI SEKARANG",
          goBack: "KEMBALI",
          notEnoughPoint:
            "Oops anda tidak mencukupi mata untuk tambah penyertaan",
          note: "NOTA",
          entryNoteOne: "1 penyertaan = 100 mata",
          entryNoteTwo: "2 penyertaan = 200 mata",
          entryNoteThree: "3 penyertaan = 300 mata",
          entryNoteFour: "4 penyertaan = 400 mata",
          entryNoteFive: "5 penyertaan = 500 mata",
          postLink: "Pautan siaran",
          uploadScreenshot: "Muat Naik Tangkapan Skrin",
          selectTShirtSize: "Pilih saiz T-Shirt anda",
          tqsubmission: "Terima kasih atas penyertaan anda!",
          submitEntries: "Ingin hantar penyertaan anda?",
          sureToUse: "Adakah anda pasti mahu menggunakan",
          pointSubmit: "mata untuk hantar",
          entriesQuestion: "penyertaan?",
          picSuccessUpload: "Gambar berjaya dimuat naik",
          clickHere: "Tekan di sini untuk mendapat lebih banyak mata",
          heretag: "<SINI>",
          here: "sini",
          herecap: "SINI",
          atHere: "DI SINI",
          stayTune:
            "Nantikan pengumuman pemenang di laman web kami atau platform media sosial Drypers",
          activityFirstPrize: "Hadiah Pertama:",
          activityOneFirstPrizeItem: "Smart Watch bernilai sehingga RM1,500",
          activityTwoFirstPrizeItem: "Smart Watch bernilai sehingga RM1,500",
          activityThreeFirstPrizeItem: "Smart Watch bernilai sehingga RM1,500",
          activityFourFirstPrizeItem: "Bekalan Drypers sepanjang hayat* +",
          activityFourFirstPrizeItemAddition:
            "beg cabin CoComelon + premium Edisi Terhad*",
          activityConsolation: "Hadiah Saguhati:",
          activityOneConsolationDetail: "T-shirt CoComelon Edisi Terhad",
          activityTwoConsolationDetail: "CoComelon Beg Kanvas Edisi Terhad",
          activityThreeConsolationDetail:
            "Bekas Makanan Drypers x CoCoMelon Edisi Terhad",
          activityFourConsolationDetail: "1000x Medal CoComelon Edisi Terhad",
          reachedMaximum:
            "Anda telah mencapai bilangan maksimum penyertaan untuk aktiviti ini",
          shareScore: "Kongsi Markah Tertinggi Anda!",
          receipt: "Resit",
          bookingDoneBefore: "(TEMPAHAN DIBUAT 1 MINGGU SEBELUM HARI LANGSUNG)",
          activitycap: "AKTIVITI",
          thankbooking: "TERIMA KASIH KERANA TEMPAHAN",
          belowLink:
            "Di bawah ialah pautan zum anda pada 28 Ogos, \njumpa lagi!",
          mayFindLink:
            "Anda juga boleh mencari pautan dalam e-mel berdaftar anda",
          joinFitAThon: "SERTAI FIT-A-THON sekarang!",
          slotBooked: "Slot anda telah ditempah!",
          notification:
            "Ahli yang dihormati, sila ambil perhatian bahawa segmen Pointshop kami akan menjalani perkhidmatan penyelenggaraan dan aktiviti penebusan akan ditangguhkan buat sementara waktu.\nUntuk sebarang pertanyaan, sila e-mel kepada kami di ",
          click: "Klik",
          termCon: "untuk Terma & Syarat",
          activityGrandPrize: "Hadiah Utama:",
          refert1:
            "Perujuk mesti memastikan ahli yang dirujuk menggunakan alamat e-mel yang sah untuk mendaftar Drypers Baby Club.",
          refert2:
            "Perujuk TIDAK dibenarkan membuat pelbagai akaun untuk merujuk rakan mereka.",
          refert3:
            "Perujuk boleh merujuk sehingga maksimum lima (5) ahli baharu dalam tempoh masa satu (1) bulan.",
          refert4:
            "Rakan baru yang dirujuk boleh dihubungi melalui e-mel ATAU nombor telefon.",
          refert5:
            "Kegagalan untuk mematuhi syarat-syarat di atas, akan mengakibatkan pembatalan akaun atau pemotongan mata ganjaran.",
          refert6: "Klik",
          refert7: "di sini",
          refert8:
            "untuk baca Terma & Syarat Peraduan 'Refer, Earn, and Double Up!' (21 Nov - 31 Dec 2023)",
          updateDetails: "Kemaskini butiran anda",
          updateText1:
            "Nikmati pengalaman yang lebih baik bersama kami dengan mengemaskini butiran peribadi anda.",
          updateText2: "200 Mata Ganjaran PERCUMA untuk anda setelah selesai!",
          updatePersonalInfo: "Kemaskini Butiran Peribadi",
          whenEdb: "Bilakah tarikh jangkaan lahir bayi?",
          confirmUpdate: "Pengesahan",
          confirmDescription:
            "Adakah anda pasti dengan butiran yang dikemaskini? Anda hanya boleh mengemaskini butiran anda setiap 6 bulan sekali. Sila semak sebelum hantar.​",
          successfulUpdate:
            "Anda berjaya mengemaskini butiran anda. Anda akan menerima 200 Mata Ganjaran.",
          updateNow: "Kemaskini Sekarang",
          nextButton: "Seterusnya",
          backButton: "Kembali",
          yesButton: "Ya, Saya Pasti",
          okayButton: "Selesai/Tutup",
          updated: "Dikemaskini",
          announcement: "PENGUMUMAN",
          announcementText:
            "Harap maklum bahawa laman web kami akan menjalani aktiviti penyelengaraan pada 25/03/2023 antara jam 10.00 malam hingga 12.00 pagi. Aktiviti penebusan hadiah Pointshop & permintaan sampel mungkin akan mengalami masalah pada masa ini. Kami memohon maaf atas sebarang kesulitan. Untuk sebarang pertanyaan, sila e-mel kami di ",
          Iam: "Saya",
          Iamyears: "Saya berumur",
          yearsold: "tahun",
          iwish: "Saya ingin mendapatkan",
          thanksforyour: "Terima kasih atas sumbangan anda!",
          donationof: "Kami telah berjaya menolak ",
          hadbeendeduct: "daripada akaun anda.",
          weappreciate:
            "Kesudian menderma & sokongan berterusan anda amat dihargai!",
          confirmdonate: "Pengesahan Derma Mata Ganjaran",
          yourkindgesture: "Sumbangan mata ganjaran anda akan membantu",
          togeta: "memenuhi hasratnya",
          thankyoufor: "Terima kasih atas sumbangan anda!",
          wish: "",
          donatenowwishes: "Derma Sekarang",
          submitwishes: "Hantar",
          backwishes: "Kembali",
          pointswishes: "Mata Ganjaran",
          ourJourney: "Perjalanan Share A Little Comfort dari 2016 ke 2022",
          totalHomeVisit: "Jumlah Rumah Yang Dikunjungi",
          totalKidsReaches: "Jumlah Kanak-Kanak Yang Dicapai",
          totalPointDonated: "Jumlah Sumbangan Mata Ganjaran",
          joinNowBtn: "Sertai kami sekarang",
          venueBlog: "Lokasi ",
          yourGenerousPoint:
            "Sumbangan ikhlas mata ganjaran anda untuk memenuhi hajat kanak-kanak ini amat dihargai.",
          donatedfrom:
            "Mata Ganjaran telah disumbangkan daripada sasaran sebanyak ",
          donatepoints: "Mata Ganjaran",
          wishingtreemission: "Misi Wishing Tree",
          thankyouRegister: "Pendaftaran Anda Berjaya!",
          youcanlogin:
            "Anda boleh log masuk untuk dapatkan sampel percuma, kumpul mata ganjaran dan tebus hadiah menarik!",
          tier: "Peringkat Keahlian",
          tierBenefit: "Faedah Peringkat Keahlian",
          tierPointRequired: "Mata Ganjaran yang harus Dikumpul",
          tierBirthday: "Mata Ganjaran Hari Jadi",
          tierReferral: "Mata Ganjaran Rujukan",
          tierMultiplier: "Gandaan Mata Ganjaran untuk kod unik",
          firstCodeTier: "Untuk Pendaftaran Kod Unik Yang Pertama",
          tierWelcome: "Mata Ganjaran Aluan",
          thankyoudonate: "Terima kasih di atas sumbangan mata ganjaran anda!",
          willbeback: "Kami akan kembali semula!",
          otpText:
            "Sila masukkan kod OTP yang telah dihantar ke telefon bimbit anda.",
          otpHead: "OTP Dihantar",
          questPopupTitle1: "Hi Mama & Papa!",
          questPopupDesc1: "telah beroperasi SEKARANG!",
          questPopupDesc2:
            "Raih lebih mata ganjaran dengan melengkapkan misi yang diberi!",
          currentQuest: "Misi Semasa Anda",
          completeProfile: "Lengkapkan Profil Anda",
          completeProfileDesc:
            "Kemaskini butiran anda dan dapatkan 200 mata ganjaran!",
          registerFirstCode: "Daftar Kod Unik Pertama Anda",
          registerFirstCodeDesc:
            "Masukkan Kod Unik pertama anda dan dapatkan mata ganjaran extra!",
          gotoQuest: "Pergi ke Misi",
          profileUpdated: "Profil sudah dikemaskini.",
          activKidsNav: "BAHARU - ActivKidz by Drypers",
          earnMoreQuest: "Dapatkan Lebih Mata Ganjaran Drypers di Misi Saya!",
          childInformation: "Informasi Anak Semasa",
          applicableChild: "*Hanya untuk kanak-kanak 3 tahun ke bawah",
          addChildren: "+ Tambah",
          childrenInformation: "",
          informasiAnak: "Informasi Anak",
          firstChild: "Pertama",
          secondChild: "Kedua",
          thirdChild: "Ketiga",
          fourthChild: "Keempat",
          fifthChild: "Kelima",
          sixthChild: "Keenam",
          seventhChild: "Ketujun",
          eightChild: "Kelapan",
          ninthChild: "Kesembilan",
          tenthChild: "Kesepuluh",
          ucTNCterm: "Terma & Syarat:",
          ucTNC:
            "Kod Unik Istimewa* TIDAK layak untuk Mata Ganjaran Berganda pada setiap 15 & 22 haribulan dan untuk Gandaan Mata Ganjaran Keahlian Berperingkat.",
          ucTNC2: "(*Kod Unik dari Expo bayi, kod khas berasaskan Kempen, dll)",
          selectOption: "Pilih Satu Pilihan",
          thankyouFeedback: "Terima kasih atas maklum balas anda.",
          thankyouContact:
            "Sila hubungi kami di talian 1-800-88-9988 atau e-mel kami di my.drypersbabyclub@vinda.com untuk bantuan selanjutnya.",
          tierReferee: "Kod Unik Pertama Oleh Rakan Yang Dirujuk",
          pointReach: "Mata Ganjaran ke",
          pointRange: "Mata dan ke atas",
          goldTierPrivilege: "Faedah Peringkat Keahlian Gold",
          platinumTierPrivilege: "Faedah Peringkat Keahlian Platinum",
          diamondTierPrivilege: "Faedah Peringkat Keahlian Diamond",
          tierPoints: "Mata Ganjaran",
          viewTierHistory: "Lihat Butiran Aktiviti Peringkat Keahlian",
          memberTierHistory: "Butiran Aktiviti Peringkat Keahlian​",
          tierHelpCenter: "Pusat Bantuan",
          viewTieringPrivileges: "Lihat Faedah Peringkat Keahlian",
          tierStatus: "Status Peringkat Keahlian",
          tierUpgradeFrom: "Peringkat Keahlian",
          tierUpgrade: "Dinaikkan",
          tierDowngrade: "Diturunkan",
          tierFrom: "dari",
          tierHighest:
            "Anda telah mencapai peringkat keahlian tertinggi - Tarikh penilaian peringkat keahlian seterusnya pada ",
          tierBy: "menjelang",
          tierGoldEg: "(cth: 170pts, Mata Ganjaran Diperoleh: 170pts)",
          tierPlatinumEg: "(cth: 170pts, Mata Ganjaran Diperoleh: 187pts)",
          tierDiamondEg: "(cth: 170pts, Mata Ganjaran Diperoleh: 255pts)",
          tierAt: "pada",
          tierHead1:
            "Terma & Syarat Sistem Peringkat Keahlian Drypers Baby Club",
          tierHead2: "Soalan Lazim (FAQ)",
          tierLine:
            "Terma & Syarat Sistem Peringkat Keahlian Drypers Baby Club ",
          tierLine1:
            "1. Mata ganjaran Drypers Baby Club diperoleh dan dikumpulkan, secara khusus melalui pendaftaran kod unik daripada pembelian produk Drypers tertentu, dan melalui pembelian Produk Penjagaan Bayi Drypers tertentu di Vindamall. Mata ganjaran juga boleh diperolehi melalui aktiviti dalam kelab.  ",
          tierLine2:
            "2. Drypers berhak untuk menentukan atau meminda pemberian mata ganjaran Drypers Baby Club untuk setiap aktiviti tanpa notis terlebih dahulu.  ",
          tierLine3: "3. Peringkat Keahlian Drypers Baby Club:  ",
          tierLine4:
            "a.     Peringkat Gold: Peringkat ini terpakai kepada ahli yang telah mengumpul antara 0 hingga 3,999 Mata Ganjaran Drypers Baby Club dalam tempoh 12 bulan.  ",
          tierLine5:
            "b.     Peringkat Platinum: Peringkat ini terpakai kepada ahli yang telah mengumpul antara 4,000 hingga 8,999 Mata Ganjaran Drypers Baby Club dalam tempoh 12 bulan. ",
          tierLine6:
            "c.     Peringkat Diamond: Peringkat ini terpakai kepada ahli yang telah mengumpul 9,000 Mata Ganjaran Drypers Baby Club atau lebih dalam tempoh 12 bulan.  ",
          tierLine7: "Sistem pemeringkatan akan dilancarkan pada ",
          tierLine7a: "4. Tarikh Pelancaran Sistem Keahlian Berperingkat: ",
          tierLine7b: "1 September 2023. ",
          tierLine7c:
            "Mulai dari tarikh ini, ahli boleh mula mengumpul Mata Ganjaran Drypers Baby Club terhadap status peringkat mereka.  ",
          tierLine8:
            "Mata Ganjaran Drypers Baby Club yang diperolehi oleh ahli tiada tarikh luput. Mata Ganjaran akan kekal sah sehingga digunakan atau keahlian ditamatkan.   ",
          tierLine8a: "5. Peluputan Mata Ganjaran Drypers Baby Club: ",
          tierLine9:
            "Tarikh semakan peringkat keahlian ditetapkan sebagai satu hari sebelum ulang tahun tarikh pendaftaran ahli. Pada tarikh ini setiap tahun, Mata Ganjaran Drypers Baby Club yang dikumpul oleh ahli akan disemak semula untuk menentukan status peringkat mereka untuk tahun seterusnya. ",
          tierLine9a: "6. Tarikh Semakan Peringkat Keahlian: ",
          tierLine10: "7. Kenaikan dan Penurunan Peringkat Keahlian:   ",
          tierLine11:
            "a.    Naik Peringkat Keahlian: Ahli akan dinaikkan ke peringkat keahlian yang lebih tinggi apabila mereka 	mengumpul jumlah Mata Ganjaran Drypers Baby Club yang diperlukan untuk peringkat tersebut dalam tempoh 12 bulan.   ",
          tierLine12:
            "b.    Menurun Peringkat Keahlian: Jika ahli gagal mengumpul Mata Ganjaran Drypers Baby Club yang diperlukan untuk peringkat semasa mereka dalam tempoh 12 bulan, mereka akan diturunkan 	ke satu peringkat yang lebih rendah.   ",
          tierLine13: "8. Pengekalan Peringkat Keahlian:  ",
          tierLine14:
            "a.    Pengekalan Peringkat Gold: Untuk mengekalkan status Peringkat Gold, ahli mesti mengumpul antara 0 hingga 3,999 Mata Ganjaran Drypers Baby Club dalam tempoh masa 12 bulan. ",
          tierLine15:
            "b.    Pengekalan Peringkat Platinum: Untuk mengekalkan status Peringkat Platinum, ahli mesti mengumpul antara 4,000 hingga 8,999 Mata Ganjaran Drypers Baby Club dalam setiap tempoh 12 bulan.  ",
          tierLine16:
            "c.    Pengekalan Peringkat Diamond: Untuk mengekalkan status Peringkat Diamond, ahli mesti mengumpul 9,000 Mata Ganjaran Drypers Baby Club atau lebih dalam setiap tempoh 12 bulan.   ",
          tierLine17: "9. Faedah dan Keistimewaan:  ",
          tierLine18:
            "a.     Setiap peringkat keahlian akan menawarkan faedah dan keistimewaan tertentu seperti yang ditetapkan oleh Drypers Baby Club.  ",
          tierLine19:
            "b.     Manfaat dan keistimewaan yang berkaitan dengan setiap peringkat keahlian tertakluk kepada perubahan atas budi bicara Drypers Baby Club.   ",
          tierLine20:
            "c.     Ahli harus merujuk kepada panduan Drypers Baby Club untuk senarai terperinci faedah dan keistimewaan yang khusus berdasarkan peringkat keahlian mereka.  ",
          tierLine21: "10. Ketidak-Boleh-Pindahan:   ",
          tierLine22:
            "a.     Mata Ganjaran Drypers Baby Club dan status peringkat keahlian tidak boleh dipindahkan, bermakna bahawa mereka tidak boleh dipindahkan atau diberikan kepada individu lain.  ",
          tierLine23:
            "b.     Mata Ganjaran Drypers Baby Club dan status peringkat keahlian tidak boleh ditukar untuk wang tunai, produk tidak tertentu, atau ganjaran tidak tertentu.   ",
          tierLine24: "11. Penamatan dan Penggantungan: ",
          tierLine25:
            "a.    Drypers Baby Club berhak untuk menamatkan atau menggantungkan keahlian ahli dan faedah serta keistimewaan yang berkaitan atas apa-apa pelanggaran terma dan syarat Drypers Baby Club atau atas apa-apa sebab lain yang dianggap wajib oleh Drypers Baby Club. ",
          tierLine26: "12. Pindaan:  ",
          tierLine27:
            "a.     Drypers Baby Club berhak untuk mengubah, meminda, atau mengemaskini Terma dan Syarat ini pada bila-bila masa.  ",
          tierLine28:
            "b.     Ahli akan dimaklumkan mengenai sebarang perubahan kepada Terma dan Syarat melalui saluran komunikasi Drypers Baby Club.   ",
          tierLine29:
            "Terma dan Syarat ini akan diperlihat oleh dan ditafsirkan mengikut undang-undang Malaysia, tanpa mengira prinsip konflik undang-undangnya.  ",
          tierLine29a: "13. Undang-Undang Yang Mengawal: ",
          tierLine30:
            "14. Dengan mengambil bahagian dalam sistem keahlian berperingkat, ahli mengakui bahawa mereka telah membaca, memahami, dan bersetuju untuk mematuhi Terma dan Syarat ini. ",
          tierLine31: "Senario:  ",
          tierLine32:
            "Bermula dari sini, ahli yang mendaftar sebelum 1 September 2023 akan dirujuk sebagai 'Ahli Sedia Ada' dan ahli yang mendaftar selepas 1 September 2023 akan dirujuk sebagai 'Ahli Baru'. ",
          tierLine33: "Contoh senario:  ",
          tierLine34: "i.    Ahli Sedia Ada  ",
          tierLine35: "a.    Menyertai Drypers Baby Club pada 1 Julai 2023  ",
          tierLine36:
            "b.    Memiliki 4,500 mata ganjaran sebelum pelancaran Peringkat Keahlian pada 1 September 2023 ",
          tierLine37:
            "c.    Dipadankan secara automatik sebagai Platinum pada pelancaran Peringkat Keahlian pada 1 September 2023 ",
          tierLine38: "d.    ",
          tierLine38a: "Mengumpul ",
          tierLine38b:
            " 5,000 Mata Ganjaran dalam tempoh 12 bulan dari 1 September 2023   ",
          tierLine39:
            "e.    Kekal sebagai Platinum pada tarikh semakan peringkat keahlian 31 Ogos 2024  ",
          tierLine40:
            "f.    Mula kitaran peringkat keahlian baru sebagai Platinum ",
          tierLine41: "ii.   Ahli Sedia Ada ",
          tierLine42: "a.    Menyertai Drypers Baby Club pada 1 Julai 2023 ",
          tierLine43:
            "b.    Memiliki 4,500 mata ganjaran sebelum pelancaran Peringkat Keahlian pada 1 Sep 2023  ",
          tierLine44:
            "c.    Dipadankan secara automatik sebagai Platinum pada pelancaran Peringkat Keahlian pada 1 Sep 2023  ",
          tierLine45: "d.    ",
          tierLine45a: "Mengumpul ",
          tierLine45b:
            "9,000 mata ganjaran dalam tempoh 12 bulan dari 1 Sep 2023  ",
          tierLine46:
            "e.    Naik peringkat keahlian kepada Diamond setelah mencapai 9,000 mata dan lebih  ",
          tierLine47:
            "f.    Kekal sebagai Diamond pada tarikh semakan peringkat keahlian seterusnya, iaitu 31 Ogos 2023  ",
          tierLine48:
            "g.    Mula kitaran peringkat keahlian baru sebagai Diamond  ",
          tierLine49: "iii.  Ahli Baru ",
          tierLine50:
            "a.    Menyertai Drypers Baby Club pada 12 Oktober 2023  ",
          tierLine51: "b.    ",
          tierLine51a: "Mengumpul ",
          tierLine51b:
            "2,000 mata ganjaran dalam tempoh 12 bulan dari 12 Oktober 2023  ",
          tierLine52:
            "c.    Kekal sebagai Gold pada tarikh semakan peringkat keahlian 11 Oktober 2024  ",
          tierLine53:
            "e.    Mula kitaran peringkat keahlian baru sebagai Gold  ",
          tierLine54: "iv.   Ahli Baru ",
          tierLine55:
            "a.    Menyertai Drypers Baby Club pada 12 Oktober 2023  ",
          tierLine56: "b.    ",
          tierLine56a: "Mengumpul ",
          tierLine56b:
            "4,500 mata ganjaran dalam tempoh 12 bulan dari 12 Oktober 2023  ",
          tierLine57:
            "c.    Naik peringkat keahlian kepada Platinum setelah mencapai 4,000 – 8,999 mata ganjaran  ",
          tierLine58:
            "d.    Kekal sebagai Platinum pada tarikh semakan peringkat keahlian seterusnya, iaitu 11 Oktober 2024  ",
          tierLine59:
            "e.    Mula kitaran peringkat keahlian baru sebagai Platinum  ",
          tierLine60: "v.    Ahli Baru ",
          tierLine61:
            "a.    Menyertai Drypers Baby Club pada 12 Oktober 2023  ",
          tierLine62: "b.    ",
          tierLine62a: "Mengumpul ",
          tierLine62b:
            "4,500 mata ganjaran dalam tempoh 12 bulan dari 12 Oktober 2023  ",
          tierLine63:
            "c.    Naik peringkat keahlian kepada Platinum setelah mencapai 4,000 – 8,999 mata ganjaran  ",
          tierLine64: "d.    ",
          tierLine64a: "Mengumpul ",
          tierLine64b:
            "5,000 mata ganjaran lagi - jumlah keseluruhan 9,500 mata ganjaran - dalam tempoh 12 bulan dari 12 Oktober 2023  ",
          tierLine65:
            "e.    Naik peringkat keahlian kepada Diamond setelah mencapai 9,000 mata ganjaran dan lebih  ",
          tierLine66:
            "f.    Kekal sebagai Diamond pada tarikh semakan peringkat keahlian seterusnya, iaitu 11 Oktober 2023  ",
          tierLine67:
            "g.    Mula kitaran peringkat keahlian baru sebagai Diamond  ",
          tierLine68:
            "* Pengemaskinian Peringkat Keahlian akan disahkan & dipaparkan dalam masa 24 jam selepas pengesahan pemenuhan syarat Mata Ganjaran Drypers Baby Club yang harus dikumpul.  ",
          tierLine69: "vi.   Ahli Sedia Ada ",
          tierLine70: "a.    Menyertai Drypers Baby Club pada 1 Julai 2023  ",
          tierLine71: "b.    Memiliki 9,500 mata ganjaran ",
          tierLine71a: "sebelum ",
          tierLine71b: "pelancaran Peringkat Keahlian pada 1 Sep 2023  ",
          tierLine72:
            "c.    Dipadankan secara automatik sebagai Diamond pada pelancaran Peringkat Keahlian pada 1 Sep 2023  ",
          tierLine73: "d.    ",
          tierLine73a: "Mengumpul ",
          tierLine73b:
            " 5,000 mata ganjaran dalam tempoh 12 bulan dari 1 Sep 2023  ",
          tierLine74:
            "e.    Diturunkan peringkat keahlian kepada Platinum pada tarikh semakan peringkat keahlian 31 Ogos 2024  ",
          tierLine75:
            "f.    Mula kitaran peringkat keahlian baru sebagai Platinum  ",
          tierLine76: "vii.  Ahli Baru",
          tierLine77:
            "a.    Menyertai Drypers Baby Club pada 12 Oktober 2023  ",
          tierLine78: "b.    ",
          tierLine78a: "Mengumpul ",
          tierLine78b:
            "4,500 mata ganjaran dalam tempoh 12 bulan dari 12 Oktober 2023 ",
          tierLine79:
            "c.    Naik peringkat keahlian kepada Platinum setelah mencapai 4,000 – 8,999 mata ganjaran pada 15 Jun 2024  ",
          tierLine80:
            "d.    Kekal sebagai Platinum pada tarikh semakan peringkat keahlian seterusnya, iaitu 11 Oktober 2024  ",
          tierLine81:
            "< Bermula Kitaran Peringkat Keahlian 12 Bulan Yang Baru >  ",
          tierLine82:
            "a.    Mula kitaran peringkat keahlian baru sebagai Platinum  at 12th Oct 2024 ",
          tierLine83: "b.    ",
          tierLine83a: "Mengumpul ",
          tierLine83b:
            "3,500 mata ganjaran dalam tempoh 12 bulan dari 12 Oktober 2024  ",
          tierLine84:
            "c.    Diturunkan peringkat keahlian kepada Gold pada tarikh semakan peringkat keahlian 11 Oktober 2025  ",
          tierLine85:
            "d.    Mula kitaran peringkat keahlian baru sebagai Gold  ",
          tierLine86: "Soalan Lazim (FAQ)   ",
          tierLine87:
            "1. Apakah aktiviti yang boleh saya lakukan dalam Drypers Baby Club?  ",
          tierLine88:
            "- Masukkan Kod Unik untuk memperoleh Mata Ganjaran Drypers Baby Club",
          tierLine89:
            "- Dapatkan 2x Mata Ganjaran pada setiap 15 dan 22 hari bulan (Kod Unik atau Mata Ganjaran Istimewa* TIDAK layak untuk 2x Mata Ganjaran pada setiap 15 & 22 haribulan.) (*Kod Unik dari Expo bayi, kod khas berasaskan Kempen, Mata Ganjaran Hari Jadi, Mata Ganjaran Aluan, Mata Ganjaran Rujukan, Gandaan Mata Ganjaran, dan lain-lain kod atau mata ganjaran khas tidak akan digandakan pada kedua-dua tarikh tersebut.) ",
          tierLine90: "- Dapatkan Mata Ganjaran Rujukan  ",
          tierLine91: "- Nikmati Gandaan Mata Ganjaran  ",
          tierLine92: "- Raih Mata Ganjaran dengan menyelesaikan misi-misi  ",
          tierLine93:
            "- Tebus ganjaran dengan Mata Ganjaran Drypers Baby Club ",
          tierLine94: "- Lebih aktiviti yang akan datang! ",
          tierLine95:
            "2. Bila Mata Ganjaran Drypers Baby Club saya akan luput? ",
          tierLine96:
            "Berita baik! Mata Ganjaran Drypers Baby Club tidak akan luput!  ",
          tierLine97: "3. Adakah peringkat keahlian saya akan luput? ",
          tierLine98:
            "Setiap peringkat ahli disemak semula dalam kitaran 12 bulan berdasarkan tarikh semakan peringkat mereka. Untuk maklumat lanjut, sila rujuk kepada Terma & Syarat Sistem Peringkat Keahlian Drypers Baby Club.  ",
          tierLine99: "4. Apakah faedah peringkat keahlian saya? ",
          tierLine100:
            "Setiap peringkat ahli disemak semula dalam kitaran 12 bulan berdasarkan tarikh semakan peringkat mereka. Untuk maklumat lanjut, sila rujuk kepada Terma & Syarat Sistem Peringkat Keahlian Drypers Baby Club.",
          tierLine101: "Klik pada ",
          tierLine101a: "ikon peringkat keahlian anda",
          tierLine101b:
            " di laman utama Drypers Baby Club untuk maklumat lanjut berkenaan faedah peringkat keahlian anda. ",
          tierLine102: "5. Bilakah tarikh semakan peringkat keahlian saya? ",
          tierLine103:
            "Jika anda mendaftar dalam Drypers Baby Club sebelum 1 September 2023, tarikh semakan peringkat keahlian anda adalah 31 Ogos setiap tahun. ",
          tierLine104:
            "Jika anda mendaftar dalam Drypers Baby Club selepas 1 September 2023, tarikh semakan peringkat keahlian anda adalah sehari sebelum ulang tahun tarikh pendaftaran anda.  ",
          tierLine105:
            "6. Bagaimanakah saya boleh menaik peringkat keahlian saya? ",
          tierLine106:
            "Peningkatan peringkat keahlian berdasarkan pada mata ganjaran yang diperolehi dalam tempoh 12 bulan sebelum tarikh semakan peringkat keahlian. Peringkat keahlian anda akan dinaikkan sebaik anda mencapai 'Mata Ganjaran yang harus Dikumpul' bagi peringkat keahlian tersebut. Sila rujuk kepada 'Mata Ganjaran yang harus Dikumpul' untuk setiap peringkat keahlian untuk untuk mara ke peringkat keahlian yang seterusnya.  ",
          tierLine107:
            "7. Adakah peringkat keahlian saya akan diturunkan jika saya tidak aktif? ",
          tierLine108:
            "Ya, penurunan peringkat keahlian berdasarkan pada mata ganjaran yang diperolehi dalam tempoh 12 bulan sebelum tarikh semakan peringkat keahlian. Jika mata ganjaran yang diperolehi tidak mencukupi, peringkat keahlian anda akan diturunkan kepada 1 peringkat keahlian yang lebih rendah. Sila rujuk kepada 'Mata Ganjaran yang harus Dikumpul’ untuk setiap peringkat keahlian untuk mara ke peringkat keahlian yang seterusnya. ",
          tierLine109:
            "8. Di manakah saya boleh mengetahui 'Mata Ganjaran yang harus Dikumpul' untuk setiap peringkat?",
          tierLine110:
            "Klik pada ikon peringkat keahlian anda di laman utama Drypers Baby Club untuk maklumat lanjut, atau klik di ",
          tierLine110a: "sini",
          tierLine110b: ".",

          uploadImageContest: "Muatnaik Gambar",
          uploadReceiptContest: "Muatnaik Resit",
          submitLinkContest: "Hantar Link",
          contest: "Peraduan",
          contestCap: "PERADUAN",
          referralError:
            "Anda telah mencapai had maksima bulanan untuk merujuk ahli baharu. Sila cuba bulan depan!",
          referralPopup:
            "Kod rujukan ini telah mencapai had maksima rujukan untuk bulan ini.",
          cookiePopup:
            "Anda telah mendaftar dengan peranti ini. Satu peranti dibenarkan mendaftar untuk satu akaun.",
          pinkFongDetails1:
            "Tebus Tiket Eksklusif Pinkfong Bounce Wonderland dengan hanya 500 mata ganjaran*! ",
          pinkFongDetails2:
            "Nikmati sehingga 13 acara permainan bernilai sehingga RM65! Jangan lepaskan detik-detik menyeronokkan ini bersama anak-anak anda. Cepat, tiket amat terhad! ",
          pinkFongDetails3: "**Tertakluk pada terma dan syarat. ",
          pinkFongDetails4: "500 mata ganjaran untuk 1 tiket.",
          pinkFongDetails5: "Terhad kepada 2 tiket setiap isi rumah.",
          pinkFongDetails6:
            "Tiket hanya untuk kanak-kanak di bawah umur 16 tahun.",
          pinkFongDetails7:
            "Kanak-kanak di bawah umur 3 tahun mesti sentiasa ditemani oleh orang dewasa.",
          pinkFongDetails8:
            " Orang dewasa perlu membeli tiket dari pautan ini ",
          pinkFongDetails8a:
            "https://www.ticket2u.com.my/event/33221/bounce-wonderland.",
          pinkFongDetails8b: " Harga RM10 untuk SATU tiket. ",
          pinkFongDetails9:
            "Tiket hanya dibenarkan untuk digunakan pada hari/waktu acara Pinkfong Bounce Wonderland yang dinyatakan di bawah. ",
          pinkFongDetails10: "Sementara stok masih ada ",
          pinkFongStep1: "Cara untuk menebus tiket:- ",
          pinkFongStep2: " Tebus tiket anda di Pointshop Drypers Baby Club ",
          pinkFongStep3:
            " Masukkan kod penebusan di halaman pembayaran dalam pautan ini : ",
          pinkFongStep3a:
            "https://www.ticket2u.com.my/event/33221/bounce-wonderland",
          pinkFongStep3b: " untuk menebus tiket PERCUMA",
          pinkFongStep4: "Butiran Tiket:-",
          pinkFongStep5: "Tarikh : 22/12/2023 - 7/1/2023",
          pinkFongStep6: "Masa : 10 pagi - 930 malam",
          pinkFongStep7: "Lokasi : Pavilion Bukit Jalil",
          ssoModalHead: "Tahniah!​!​",
          ssoModal1:
            "Akaun Vindamall yang baharu telah dicipta dengan e-mel dan kata laluan Drypers Baby Club anda. Kini anda boleh log masuk ke laman Vindamall dengan butiran log masuk Drypers Baby Club.",
          ssoUpdate: "Kemaskini terbaru:",
          ssoUpdate1: "Single Sign On (SSO) bermula pada dd/mm/yyyy.",
          ssoUpdate2:
            "Berita baik! Kini anda boleh menggunakan e-mel yang sama untuk log masuk ke Drypers Baby Club dan Vindamall.",
          ssoUpdate3:
            "Untuk pengguna Vindamall sedia ada, anda boleh menggunakan e-mel dan kata laluan Vindamall untuk log masuk ke Drypers Baby Club.",
          ssoExist:
            "Anda telah mendaftar sebagai ahli Drypers Baby Club. Sila log masuk.",
          ssoVindamallPassword: "Sila log masuk dengan kata laluan Vindamall.",
          ssoNotRegistered:
            "Alamat emel ini tidak berdaftar. Sila daftar di halaman pendaftaran.",
          ssoCheckPassword: "Sila semak kata laluan anda.",
          ssoPasswordValidation:
            "Kata laluan mesti mengandungi sekurang-kurangnya 1 huruf besar, 1 huruf kecil, 1 digit, 1 aksara khas (@$!%*?&), dan terdiri daripada sekurang-kurangnya 8 aksara.",
          ssoVindamallExist:
            "Akaun Vindamall telah disahkan. Anda boleh teruskan log masuk dengan e-mel dan kata laluan Vindamall. Akaun Drypers Baby Club akan dicipta secara automatik untuk anda.",
          passwordNotStrong:
            "Kata laluan tidak cukup kuat. Sila tetapkan semula kata laluan anda.",
          passwordNotStrongHeader: "",
          wrongPasswordGeneral:
            "Kata laluan yang anda masukkan tidak sah. Sila semak kata laluan anda. Jika anda masih gagal untuk log masuk, sila klik Lupa Kata Laluan & set semula kata laluan anda.",
          internalError: "Ralat",
          closeButton: "Tutup",
          bbcReceipt: "RESIT BABY CARE",
          receiptSample: "Contoh Resit",
          receiptUploadTnc:
            "Kod Unik Istimewa* TIDAK layak untuk Mata Ganjaran Berganda pada setiap 15 & 22 haribulan dan untuk Gandaan Mata Ganjaran Keahlian Berperingkat.",
          receiptUploadTnc2:
            "(*Kod Unik dari Expo bayi, kod khas berasaskan Kempen, dll)",
          clickToUpload:
            "Klik imej di bawah untuk muat naik resit dan raih mata ganjaran!",
          uploadReceiptPhoto: "Muat naik gambar resit",
          uploadReceipt: "Muat Naik Resit",
          supportedFormat:
            "*Format imej yang disokong: JPEG, JPG, PNG sahaja (max. 10MB)",
          clearReceipt:
            "*Sila pastikan resit anda jelas dengan produk yang mengambil bahagian.",
          receiptValidateNote:
            "Nota: Resit anda akan disahkan dalam masa 3-5 hari bekerja dari tarikh penerimaan resit",
          storeName: "Nama Stor Online/Offline*",
          select: "-Pilih-",
          pleaseSpecify: "Sila nyatakan:",
          receiptDate: "Tarikh Resit*",
          receiptNumber: "Nombor Resit*",
          receiptNumberPlaceholder: "Masukkan nombor resit",
          purchasedProducts: "Produk yang Dibeli*",
          quantity: "Kuantiti*",
          quantityPlaceholder: "Masukkan kuantiti",
          addProducts: "Tambah Produk",
          doublePointsNote:
            "Mata Ganjaran Berganda (15 & 22 haribulan) adalah berdasarkan kepada tarikh reset dimuat naik. Mata ganjaran akan dikreditkan dalam masa 3-5 hari bekerja.",
          onlineSample: "Contoh resit pembelian online:",
          offlineSample: "Contoh resit pembelian offline:",
          "Baby Care Product Rewards": "Ganjaran Produk Baby Care",
          areyousuretitle: "Adakah anda pasti",
          areyousuretodelete: "Adakah anda pasti untuk buang imej ini",
          delete: "Padam",
          uploadSuccess:
            "Resit anda telah berjaya dimuat naik dan akan disahkan dalam masa 3-5 hari bekerja",
          eligibility: "Kelayakan",
          eligibilityTnc:
            "Ciri ini tersedia secara eksklusif untuk ahli Drypers Baby Club yang telah membeli Produk Drypers Baby Care SAHAJA.",
          uploadRequirement: "Keperluan Muat Naik Resit",
          uploadRequirementTnc:
            "Untuk mengumpul Mata Ganjaran Drypers Baby Club, ahli mesti memuat naik imej resit yang jelas dan haruslah mengandungi Produk Drypers Baby Care. Hanya resit rasmi daripada kedai online & offline diterima, resit mesti memaparkan penerangan produk, tarikh pembelian, nombor resit dan nama kedai dengan jelas.",
          eligibleProduct: "Produk Layak",
          eligibleProductTnc:
            "Hanya pembelian Produk Drypers Baby Care sahaja yang layak untuk penebusan mata ganjaran melalui muat naik resit.",
          pointAllocation: "Peruntukan Mata Ganjaran",
          pointAllocationTnc:
            "Selepas pengesahan penerimaan berjaya, ahli akan menerima mata ganjaran berdasarkan kepada Produk Drypers Baby Care yang dibeli serta layak. Mata ganjaran akan dikreditkan ke akaun ahli dalam masa 3 - 5 hari bekerja.",
          dpd: "Hari Mata Ganjaran Berganda",
          dpdTnc:
            "Pemberian Mata Ganjaran Berganda adalah berdasarkan kepada tarikh resit dimuat naik, iaitu pada 15hb & 22hb setiap bulan. Mata ganjaran akan dikreditkan ke akaun ahli dalam masa 3 - 5 hari bekerja.",
          validPeriod: "Tempoh Sah",
          validPeriodTnc:
            "Hanya resit bertarikh 1 Mei 2024 dan seterusnya diterima. Resit mesti dimuat naik dalam tempoh 30 hari dari tarikh pembelian untuk melayakkan penerimaan mata ganjaran.",
          fraudPrevent: "Pencegahan Penipuan",
          fraudPreventTnc:
            "Vinda memegang hak mutlak untuk menyekat, membatalkan kelayakan dan melucutkan semua mata ganjaran yang diberi, jika sebarang percubaan untuk memanipulasi resit atau aktiviti penipuan dikesan.",
          duplicateTnc:
            "Resit duplikasi akan dibatalkan. Sila pastikan resit adalah unik dan tidak  dimuat naik secara berulang.",
          vindaTnc:
            "Vinda Malaysia, mengikut budi bicara mutlaknya, berhak untuk mengubah suai peraturan dan syarat tanpa notis awal, jika diperlukan, untuk manfaat semua ahli Kelab.",
          clickHereFor: "Klik di sini untuk",
          imageAndPoints: "Imej Produk & Mata Ganjaran",
          supportedFormatTitle: "Format imej yang disokong",
          supportedFormatTnc: "JPEG, JPG, PNG sahaja, tidak melebihi 10MB.",
          receiptDateError:
            "Hanya resit bertarikh 1hb Mei 2024 ke atas yang diterima",
          failed: "Gagal",
          submitAgain: "Hartar Semula",
          mysteryBoxZeroCountDescription:
            "Daftar Kod Unik anda ATAU muat naik Resit Baby Care untuk membuka kotak misteri dan sekaligus melayakkan penyertaan ke Hadiah Mingguan serta Hadiah Utama.",
          mysteryBoxWarning1Week:
            "Kami dapati anda masih belum membuka kotak misteri anda. Sila buka dan nikmati ganjaran menarik!",
          youHave: "Anda mempunyai ",
          mysteryBoxTobeOpened: " kotak misteri yang perlu dibuka",
          mysteryHomeTitleDesc:
            "Dapatkan ganjaran dengan Kotak Misteri dan layakkan penyertaan anda untuk hadiah mingguan serta hadiah utama dengan mendaftar Kod Unik dan/atau muat naik resit Baby Care!",
          tncWord: "Tertakluk kepada Terma & Syarat",
          entryEarned: "Penyertaan Diperolehi",
          forGrandPrize: "Untuk Hadiah Mingguan & Utama",
          prize: "Hadiah",
          howToWin: "Cara Untuk Menang",
          keyInUniqueCode: "Daftar Kod Unik",
          uploadBabyCare: "Muat Naik Resit Baby Care",
          myBoxOpen: " kotak untuk dibuka",
          myBox: " kotak",
          youHaveOpened: "Anda telah membuka ",
          myBoxGrandPrize: "HADIAH UTAMA",
          FiveWinnerWin: "5 PEMENANG HADIAH MINGGUAN!",
          myBoxAndMore: "...DAN LEBIH BANYAK HADIAH ",
          mysteryBox: " KOTAK MISTERI ",
          prizeToReveal: " UNTUK DIBUKA!",
          aeonVoucher: "Baucar Aeon RM50",
          petronasGiftCard: "Kad Petronas RM50",
          submission: "Penghantaran",
          howToWin1:
            "Beli mana-mana produk lampin Drypers dan/atau produk Baby Care.",
          howToWin2: "Daftar Kod Unik  anda dan/atau muat naik resit. ",
          howToWin2Part2:
            "Penghantaran resit akan disemak dan disahkan dalam tempoh 3-5 hari bekerja. Hanya penghantaran yang berjaya disahkan akan layak menerima ganjaran dan penyertaan.",
          howToWin3: "Setiap penghantaran yang sah akan layak untuk:",
          howToWin4:
            "Semak laman Facebook Drypers Malaysia untuk pengumuman pemenang. Pemenang hadiah mingguan akan diumumkan setiap minggu, manakala pemenang hadiah utama akan diumumkan selepas kempen tamat. ",
          howToWinPrize1: "Kotak Misteri",
          howToWinPrize2: "Penyertaan Hadiah Mingguan",
          howToWinPrize3: "Penyertaan Hadiah Utama ",
          openAnotherBox: "Buka Kotak Seterusnya",
          viewMyReward: "Lihat Ganjaran Saya",
          uploadReceiptMyBoxMessage:
            "Setelah diluluskan, anda boleh unlock Kotak Misteri anda.",
          scanCodeMyBoxMessage: "Tahniah! Anda telah menerima Kotak Misteri.",
          newSucc: "Resit berjaya dimuat naik",
          newUploadSucc: "Resit akan disahkan dalam masa 3-5 hari bekerja.",
          newRegistered: "Kod Unik Diterima",
          youHaveReceived: "Anda telah memperolehi ",
          smallPoints: " mata ganjaran!",
          tng: "Pin Tambah Nilai TnG",
          aeon: "eBaucar AEON",
          vinda: "eBaucar Vindamall",
          beforeEmail:
            "Ahli akan menerima e-Baucar AEON dalam Aplikasi iAEON (untuk pengguna Aplikasi iAEON), ATAU melalui emel daripada AEON (untuk bukan pengguna Aplikasi iAEON) berdasarkan ",
          theEmail: "emel DBC",
          afterEmail: " mereka dalam tempoh 5 hari bekerja.​",
          gifConfirm: "DAFTAR KOD UNIK",
          gifCancel: "KEMBALI KE LAMAN UTAMA",
          scan3xTitle1: "Anda telah memperoleh ",
          scan3xTitle2: "mata ganjaran!",
          scan3xDescription1: "Anda diberi ganjaran ",
          scan3xDescription2: " 3X Mata Ganjaran",
          scan5xDescription2: " RM5 Vindamall eBaucar",
          scan10xDescription2: " RM10 Vindamall eBaucar",
          scan3xDescription3: " kerana mendaftar",
          scan3xDescription4: " Kod Unik Drypers Pant's Diaper Pertama",
          scan5xDescription4: " Kod Unik Kelima",
          scan10xDescription4: "  Kod Unik Kesepuluh",
          scan3xDescription5: " anda.",
          scan3xDescription6: "Tebus ganjaran anda sekarang di",
          scan3xButton: "SETERUSNYA",
          scan3XPointShop: "Pusat Ganjaran!",
        },
      },
    },
    lng: localStorage.getItem("language") || "en",
  });

export default i18n;
