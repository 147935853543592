import React from "react";
import Header from "../../../component/dashboard/Header";
import ContentHeader from "../../../component/dashboard/ContentHeader";
import { useTranslation } from "react-i18next";

const ProductTable = () => {
  const { t } = useTranslation();
  return (
    <div id="upload-receipt-page">
      <Header />
      <div className="mt-140">
        <div className="d-flex-center">
          <table className="product-table">
            <tr>
              <th>Item</th>
              <th>Size</th>
              <th>Points</th>
            </tr>
            <tr>
              <td>Drypers Baby Bath 750ml</td>
              <td>750ml</td>
              <td>90</td>
            </tr>
            <tr>
              <td>Drypers Baby Shampoo 750ml</td>
              <td>750ml</td>
              <td>90</td>
            </tr>
            <tr>
              <td>Drypers Baby Talcum Oat 500g</td>
              <td>500x2</td>
              <td>90</td>
            </tr>
            <tr>
              <td>Drypers Baby Head To Toe Oat/Fruit/Avo/Alm/Shea 450ml</td>
              <td>450ml</td>
              <td>50</td>
            </tr>
            <tr>
              <td>Drypers Baby Head To Toe Oat/Fruit/Avo 2x450ml</td>
              <td>450ml x 2</td>
              <td>100</td>
            </tr>
            <tr>
              <td>Drypers Baby Head To Toe Oat/Fruit/Avo 2x450ml</td>
              <td>450ml x 2</td>
              <td>100</td>
            </tr>
            <tr>
              <td>Drypers Baby Head To Toe Oat/Fruit/Avo/Alm/Shea 650ml</td>
              <td>650ml</td>
              <td>90</td>
            </tr>
            <tr>
              <td>Drypers Baby Head To Toe Oat/Fruit/Avo 220ml</td>
              <td>220ml</td>
              <td>40</td>
            </tr>
            <tr>
              <td>Drypers Baby Wipes Oat 2x30</td>
              <td>60s</td>
              <td>40</td>
            </tr>
            <tr>
              <td>Drypers Baby Wipes Oat 2x30 F.F</td>
              <td>60s</td>
              <td>40</td>
            </tr>
            <tr>
              <td>Drypers Baby Wipes H&M 2x60</td>
              <td>120s</td>
              <td>90</td>
            </tr>
            <tr>
              <td>Drypers Baby Wipes Oat F.F 2x80</td>
              <td>160s</td>
              <td>90</td>
            </tr>
            <tr>
              <td>Drypers Baby Wipes Oat 2x80</td>
              <td>160s</td>
              <td>90</td>
            </tr>
            <tr>
              <td>Drypers Baby Wipes Oat F.F</td>
              <td>160s</td>
              <td>90</td>
            </tr>
            <tr>
              <td>Drypers Baby Wipes Oat</td>
              <td>30s</td>
              <td>30</td>
            </tr>
            <tr>
              <td>ActivKidz Drypers Fruity Fresh/Super Berries</td>
              <td>200ml</td>
              <td>60</td>
            </tr>
            <tr>
              <td>ActivKidz Drypers Fruity Fresh/Super Berries</td>
              <td>400ml</td>
              <td>100</td>
            </tr>
            <tr>
              <td>ActivKidz Drypers Fruity Fresh/Super Berries</td>
              <td>550ml</td>
              <td>140</td>
            </tr>
            <tr>
              <td>ActivKidz Drypers Detangling / AntiOdour Shampoo</td>
              <td>200ml</td>
              <td>60</td>
            </tr>
            <tr>
              <td>ActivKidz Drypers Detangling / AntiOdour Shampoo</td>
              <td>550ml</td>
              <td>140</td>
            </tr>
            <tr>
              <td>ActivKidz Drypers Handwash</td>
              <td>200ml</td>
              <td>60</td>
            </tr>
            <tr>
              <td>Lotion Tissue 5x40pcs</td>
              <td>200pcs</td>
              <td>60</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ProductTable;
