import { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const VoucherHistory = ({ user }) => {
  const [filter, setFilter] = useState("current");
  const { t } = useTranslation();

  const renderVouchers = () => {
    if (user && user.vouchers && user.vouchers.length) {
      if (filter === "current") {
        return user.vouchers
          .filter((x) => !x.used)
          .map((voucher) => (
            <Link
              key={voucher.id}
              className="catalog-item"
              to={{ pathname: "/voucherorder", data: voucher.id }}
              style={{ textDecoration: "none" }}
            >
              <div className="catalog-item-img-container">
                <img
                  src={voucher.voucherImage}
                  alt="item"
                  className="catalog-item-img img-fluid"
                />
              </div>
              <p className="m-0 p-0 catalog-item-text">{voucher.name}</p>
            </Link>
          ));
      } else {
        return user.vouchers
          .filter((x) => x.used)
          .map((voucher) => (
            <Link
              key={voucher.id}
              className="catalog-item"
              to={{ pathname: "/voucherorder", data: voucher.id }}
              style={{ textDecoration: "none" }}
            >
              <div className="catalog-item-img-container">
                <img
                  src={voucher.voucherImage}
                  alt="item"
                  className="catalog-item-img img-fluid"
                />
              </div>
              <p className="m-0 p-0 catalog-item-text">{voucher.name}</p>
            </Link>
          ));
      }
    } else {
      return null;
    }
  };

  return (
    <div id="voucher-history">
      <div className="selection-container">
        <div className="categories-container">
          <div
            className={`category ${filter === "current" ? "active" : ""}`}
            onClick={() => setFilter("current")}
          >
            <p className="m-0 p-0">{t("current")}</p>
          </div>
          <div
            className={`category ${filter === "history" ? "active" : ""}`}
            onClick={() => setFilter("history")}
          >
            <p className="m-0 p-0">{t("history")}</p>
          </div>
        </div>
      </div>

      <div className="catalog-item-container">{renderVouchers()}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
  };
};

export default connect(mapStateToProps)(VoucherHistory);
