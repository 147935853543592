import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { checkSession } from "./redux/actions/userActions";
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Auth
import LandingPage from "./page/user/Auth/LandingPage";
import Signin from "./page/user/Auth/Signin";
import Verify from "./page/user/Auth/Verify";
import ForgotPassword from "./page/user/Auth/ForgotPassword";
import ResetPassword from "./page/user/Auth/ResetPassword";
import SignupSuccess from "./page/user/Auth/SignupSuccess";
// import Signup from "./page/user/Auth/Signup";
//Main Pages
import Dashboard from "./page/user/Dashboard";
import ScanCode from "./page/user/ScanCode";
import ViewNews from "./page/user/ViewNews";
import Inbox from "./page/user/Inbox";
import Help from "./page/user/Help";
import HelpInfo from "./page/user/HelpInfo";
import HelpInfo2 from "./page/user/HelpInfo2";
import HelpInfo3 from "./page/user/HelpInfo3";
// import Campaign from "./page/user/Campaign";
//Campaign Activity
// import ActivityOne from "./page/user/Activity/ActivityOne";
// import ActivityOneSubmit from "./page/user/Activity/ActivityOneSubmit";
// import ActivityTwo from "./page/user/Activity/ActivityTwo";
// import ActivityTwoSubmit from "./page/user/Activity/ActivityTwoSubmit";
// import ActivityThree from "./page/user/Activity/ActivityThree";
// import ActivityThreeSubmit from "./page/user/Activity/ActivityThreeSubmit";
// import ActivityFour from "./page/user/Activity/ActivityFour";
// import ActivityFourThank from "./page/user/Activity/ActivityFourThank";
//User Profile
import UserProfile from "./page/user/Profile/UserProfile";
import EditProfile from "./page/user/Profile/EditProfile";
import ViewAddress from "./page/user/Profile/ViewAddress";
import AddAddress from "./page/user/Profile/AddAddress";
import BabyInformation from "./page/user/Profile/BabyInformation";
import AddBabyInfo from "./page/user/Profile/AddBabyInfo";
import ReferAndEarn from "./page/user/Profile/ReferAndEarn";
import ProductsInfo from "./page/user/ProductsInfo";
import Quest from "./page/user/Quest/Quest";
import Tier from "./page/user/Tier/Tier";
import TierHistory from "./page/user/Tier/TierHistory";
import TierHelpCenter from "./page/user/Tier/TierHelpCenter";
//Order System
import PointShop from "./page/user/Order/PointShop";
import RewardDetail from "./page/user/Order/RewardDetail";
import AddToCart from "./page/user/Order/AddToCart";
import ConfirmOrder from "./page/user/Order/ConfirmOrder";
import ConfirmOrderPregnant from "./page/user/Order/ConfirmOrderPregnant";
import OrderTracking from "./page/user/Order/OrderTracking";
import VoucherOrder from "./page/user/Order/VoucherOrder";
//Point Pages
import PointHistory from "./page/user/Point/PointHistory";
import ViewPoints from "./page/user/Point/ViewPoints";
// import WaysToCollect from "./page/user/Point/WaysToCollect";
//Free Sample
import PickSample from "./page/user/FreeSample/PickSample";
import PickSize from "./page/user/FreeSample/PickSize";
import SampleDetail from "./page/user/FreeSample/SampleDetail";
import ConfirmFreeSample from "./page/user/FreeSample/ConfirmOrder";
import Feedback from "./page/user/FreeSample/Feedback";
//Wishing Tree
import Charity from "./page/user/WishingTree/Charity";
import WishingTree from "./page/user/WishingTree/WishingTree";
//Contest
import Contest from "./page/user/Contest/contest";
import Activity from "./page/user/Contest/activity";
import Submit from "./page/user/Contest/submit";
//Upload
import UploadReceipt from "./page/user/Upload/UploadReceipt";
import UploadReceiptSuccess from "./page/user/Upload/UploadReceiptSuccess";

// ADMIN
import AdminLogin from "./page/admin/auth/Login";
import DiaperDashboard from "./page/admin/settings/BrandDashboard";
import AddBrand from "./page/admin/settings/brand/AddBrand";
import EditBrand from "./page/admin/settings/brand/EditBrand";
import BrandDetails from "./page/admin/settings/brand/BrandDetails";
import BrandAssign from "./page/admin/settings/brand/BrandAssign";
import SampleDashboard from "./page/admin/settings/SampleDashboard";
import AddSample from "./page/admin/settings/sample/AddSample";
import EditSample from "./page/admin/settings/sample/EditSample";
import ItemDashboard from "./page/admin/settings/ItemDashboard";
import AddItem from "./page/admin/settings/item/AddItem";
import EditItem from "./page/admin/settings/item/EditItem";
import AddItemPregnant from "./page/admin/settings/pregnantItem/AddItem";
import EditItemPregnant from "./page/admin/settings/pregnantItem/EditItem";
import VoucherDashboard from "./page/admin/settings/VoucherDashboard";
import AddVoucherType from "./page/admin/settings/voucher/AddVoucherType";
import BatchDetails from "./page/admin/settings/voucher/BatchDetails";
import UploadVoucher from "./page/admin/settings/voucher/UploadVoucher";
import VoucherDetails from "./page/admin/settings/voucher/VoucherDetails";
import CodeDashboard from "./page/admin/settings/CodeDashboard";
import CodeDetails from "./page/admin/settings/code/CodeDetails";
import GenerateCodes from "./page/admin/settings/code/GenerateCodes";
import NewsDashboard from "./page/admin/settings/NewsDashboard";
import ArticleDashboard from "./page/admin/settings/ArticleDashboard";
import ContestDashboard from "./page/admin/settings/ContestDashboard";
import BlogDashboard from "./page/admin/settings/BlogDashboard";
import JourneyDashboard from "./page/admin/settings/JourneyDashboard";
import WishingTreeDashboard from "./page/admin/settings/WishingTreeDashboard";
import AddNews from "./page/admin/settings/news/AddNews";
import AddArticle from "./page/admin/settings/article/AddArticle";
import AddContest from "./page/admin/settings/contest/AddContest";
import AddBlog from "./page/admin/settings/blog/AddBlog";
import AddWishingTree from "./page/admin/settings/wishingtree/addWishingTree";
import EditWishingTree from "./page/admin/settings/wishingtree/editWishingTree";
import EditNews from "./page/admin/settings/news/EditNews";
import EditJourney from "./page/admin/settings/journey/editJourney";
import EditContest from "./page/admin/settings/contest/EditContest";
import EditArticle from "./page/admin/settings/article/EditArticle";
import EditBlog from "./page/admin/settings/blog/EditBlog";
import SampleQuestionDashboard from "./page/admin/settings/SampleQuestionDashboard";
import AddQuestion from "./page/admin/settings/sample/AddQuestion";
import DonateDashboard from "./page/admin/settings/DonateDashboard";
import AddDonate from "./page/admin/settings/donate/AddDonate";
import EditAccount from "./page/admin/views/EditAccount";
import Account from "./page/admin/views/Account";
import AdminPointShop from "./page/admin/views/PointShop";
import AdminPointHistory from "./page/admin/views/PointHistory";
import PointUserReport from "./page/admin/views/PointUserReport";
// import AdminInbox from "./page/admin/views/Inbox";
import AdminFreeSample from "./page/admin/views/FreeSample";
import AdminScan from "./page/admin/views/Scan";
import PromoterDashboard from "./page/admin/settings/PromoterDashboard";
import AddPromoter from "./page/admin/settings/promoter/AddPromoter";
import EditPromoter from "./page/admin/settings/promoter/EditPromoter";
import MassCreditDashboard from "./page/admin/settings/MassCreditDashboard";
import MassCredit from "./page/admin/settings/massCredit/MassCredit";
import MassCreditDetails from "./page/admin/settings/massCredit/MassCreditDetails";
import AdminActivity from "./page/admin/views/AdminActivity";
import PregnantDashboard from "./page/admin/settings/PregnantDashboard";
import BulkCreateUsersDashboard from "./page/admin/settings/BulkCreateUsersDashboard";
import MysteryBoxHome from "./page/user/MysteryBox/MysteryBoxHome";
import ScanCodeSuccess from "./page/user/ScanCodeSuccess";
import ProductTable from "./page/user/Upload/ProductTable";

function App({ user, isAuthenticated, type, language, checkSession }) {
  const location = useLocation();
  const [showModal, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const { t } = useTranslation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => checkSession(), []);
  const history = useHistory();

  useEffect(() => {
    if (location.pathname === "/referandearn") {
      history.push("/referandearn");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (user && type === "user") {
      const beautifyNumber = (number) => {
        if (number[0] !== "0") {
          // is not 0
          return "+" + number;
        } else {
          // is starting with 0
          return "+6" + number;
        }
      };
      const uuid = user.id;
      const gender = user.gender;
      const bd = user.dob;
      const dob = new Date(bd);
      const year = dob.getFullYear();
      const month =
        (dob.getMonth() + 1).toString().length === 1
          ? "0" + (dob.getMonth() + 1)
          : dob.getMonth() + 1;
      const day =
        dob.getDate().toString().length === 1
          ? "0" + dob.getDate()
          : dob.getDate();
      const birthday = `${year}-${month}-${day}`;
      const duedate = user.edb;
      const edb = new Date(duedate).toISOString();
      // const yearedb = edb.getFullYear();
      // const monthedb = (edb.getMonth() + 1).toString().length === 1 ? "0" + (edb.getMonth() + 1) : edb.getMonth() + 1;
      // const dayedb = edb.getDate().toString().length === 1 ? "0" + edb.getDate() : edb.getDate();
      // const momduedate = `${yearedb}-${monthedb}-${dayedb}`;
      const transaction_count = user.redemptions.length;
      const has_transacted = transaction_count ? true : false;
      const gdpr_optin = true;
      const name = user.name;
      const email = user.email;
      const email_optin = true;
      const phone_number = beautifyNumber(user.number);
      const sms_optin = true;
      const whatsapp_optin = true;
      // const language =
      const returning = user.commonid || user.myuserid ? true : false;

      const address = user.addresses.filter((address) => address.active)[0];
      const state = address ? address.state : null;

      const custom = {
        pregnancy_stage: user.pregnancyStage,
        race: user.race,
        user_code: user.referByCode,
        point_balance: user.totalPoints,
        state,
        c_tiers: user.tier,
      };

      if (user.edb) custom.mom_due_date = edb;

      const today = new Date();

      for (let i = 0; i < user.babyInfos.length; i++) {
        const babyInfo = user.babyInfos[i];

        const num = i + 1;
        const dobKey = `baby_dob_${num}`;
        const genderKey = `baby_gender_${num}`;
        const brandKey = `baby_diaper_brand_${num}`;
        const formatKey = `baby_diaper_format_${num}`;
        const sizeKey = `baby_diaper_size_${num}`;
        const ageKey = `baby_age_${num}`;

        const thisBrand = user.brands.filter(
          (brand) => brand.id === babyInfo.brandId
        )[0];

        const brandName = thisBrand ? thisBrand.name : "";
        const formatName = thisBrand
          ? thisBrand.formats.filter(
              (format) => format.id === babyInfo.formatId
            )[0].name
          : "";
        const sizeName = thisBrand
          ? thisBrand.sizes.filter((size) => size.id === babyInfo.sizeId)[0]
              .name
          : "";

        const babyDob = new Date(babyInfo.birthDate).toISOString();
        // const babyYear = babyDob.getFullYear();
        // const babyMonth = (babyDob.getMonth() + 1).toString().length === 1 ? "0" + (babyDob.getMonth() + 1).toString() : (babyDob.getMonth() + 1).toString();
        // const babyDay = (babyDob.getDate()).toString().length === 1 ? "0" + (babyDob.getDate()).toString() : (babyDob.getDate()).toString();
        // const bbDob = `${babyYear}-${babyMonth}-${babyDay}`;
        const babyGender = babyInfo.gender;

        const babyAge = parseInt(
          (today - new Date(babyInfo.birthDate)) / (1000 * 60 * 60 * 24 * 30)
        );

        // console.log({ num, dobKey, genderKey, brandKey, formatKey, sizeKey, babyDob, babyGender, brandName, formatName, sizeName });
        custom[dobKey] = babyDob;
        custom[genderKey] = babyGender;
        custom[brandKey] = brandName;
        custom[formatKey] = formatName;
        custom[sizeKey] = sizeName;
        custom[ageKey] = babyAge;
      }

      const insiderObject = {
        uuid,
        gender,
        birthday,
        has_transacted,
        transaction_count,
        gdpr_optin,
        name,
        email,
        email_optin,
        phone_number,
        sms_optin,
        whatsapp_optin,
        language,
        returning,
        custom,
      };

      // console.log(insiderObject);

      window.insider_object = window.insider_object || {};

      window.insider_object.user = insiderObject;
      // comment out for sandbox
      if (window.Insider && window.Insider.eventManager) {
        window.Insider.eventManager.dispatch("init-manager:re-initialize");
      } else {
        console.error("Insider or eventManager is undefined");
      }
    }
  }, [user, type, language, location.pathname]);

  return (
    // <BrowserRouter basename="">
    <div>
      <Modal
        show={showModal}
        onHide={handleClose}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Body className="text-center">
          <div>
            <h1
              style={{ paddingLeft: "30px", paddingRight: "30px" }}
              className="dashboardNewsTitle"
            >
              {t("announcement")}
            </h1>
            <h2
              style={{
                fontSize: "18px",
                marginBottom: "20px",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingTop: "20px",
              }}
              className="UpdateText"
            >
              {t("announcementText")}
              <a
                href="mailto:my.drypers@vinda.com"
                className="contact-link m-0 p-0"
              >
                my.drypers@vinda.com
              </a>
            </h2>
          </div>
        </Modal.Body>
      </Modal>
      <Switch>
        <Route exact path="/faq/how-to-collect-points" component={HelpInfo} />
        <Route exact path="/faq/drypers-sample-request" component={HelpInfo2} />
        <Route exact path="/faq/resend-activation-link" component={HelpInfo3} />
        <Route exact path="/productsinfo" component={ProductsInfo} />
        {/* Wishing Tree */}
        <Route exact path="/wishingtree" component={Charity} />
        <Route exact path="/wishingtree/donate" component={WishingTree} />
        {!user || !isAuthenticated || !type ? (
          <Switch>
            <Route exact path="/:language" component={LandingPage} />
            <Route exact path="/:language/signin" component={Signin} />
            <Route
              exact
              path="/:language/registration/success"
              component={SignupSuccess}
            />
            <Route exact path="/admin/login" component={AdminLogin} />
            <Route
              exact
              path="/registration/confirm-and-verify"
              component={Verify}
            />
            <Route exact path="/password/forgot" component={ForgotPassword} />
            <Route exact path="/password/reset" component={ResetPassword} />
            {window.location.href.split("/")[3] === "admin" ? (
              <Redirect to="/admin/login" />
            ) : (
              <Redirect to={`/${language}`} />
            )}
          </Switch>
        ) : user && isAuthenticated && type === "user" ? (
          <Switch>
            {/* <Route exact path="/mysteryboxhome" component={MysteryBoxHome} /> */}
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/scancode" component={ScanCode} />
            <Route exact path="/scancode/success" component={ScanCodeSuccess} />
            {/* <Route exact path="/campaign" component={Campaign} /> */}
            {/* campaign activity */}
            {/* <Route exact path="/activityone" component={ActivityOne} />
						<Route exact path="/activityone/submitform" component={ActivityOneSubmit} />
						<Route exact path="/activitytwo" component={ActivityTwo} />
						<Route exact path="/activitytwo/submitform" component={ActivityTwoSubmit} />
						<Route exact path="/activitythree" component={ActivityThree} />
						<Route exact path="/activitythree/submitform" component={ActivityThreeSubmit} />
						<Route exact path="/activityfour" component={ActivityFour} />
						<Route exact path="/activityfour/thank" component={ActivityFourThank} /> */}
            {/* point shop */}
            <Route exact path="/pointshop" component={PointShop} />
            <Route exact path="/rewarddetail" component={RewardDetail} />
            <Route exact path="/order/addtocart" component={AddToCart} />
            <Route exact path="/order/confirm" component={ConfirmOrder} />
            <Route
              exact
              path="/order/pregnant/confirm"
              component={ConfirmOrderPregnant}
            />
            <Route exact path="/order/track" component={OrderTracking} />
            <Route exact path="/voucherorder" component={VoucherOrder} />
            {/* user */}
            <Route exact path="/userprofile" component={UserProfile} />
            <Route exact path="/editprofile" component={EditProfile} />
            <Route exact path="/viewAddress" component={ViewAddress} />
            <Route exact path="/addAddress" component={AddAddress} />
            <Route exact path="/addbabyinfo" component={AddBabyInfo} />
            <Route exact path="/viewbabyinfo" component={BabyInformation} />
            <Route exact path="/pointhistory" component={PointHistory} />
            <Route exact path="/viewpoints" component={ViewPoints} />
            <Route exact path="/referandearn" component={ReferAndEarn} />
            <Route exact path="/help" component={Help} />
            <Route exact path="/viewnews" component={ViewNews} />
            <Route exact path="/quest" component={Quest} />
            <Route exact path="/tier" component={Tier} />
            <Route exact path="/tierHistory" component={TierHistory} />
            <Route exact path="/tierHelpCenter" component={TierHelpCenter} />
            {/* Contest */}
            <Route exact path="/contest" component={Contest} />
            <Route exact path="/activity" component={Activity} />
            <Route exact path="/submit" component={Submit} />
            {/* sample */}
            <Route exact path="/picksample" component={PickSample} />
            <Route exact path="/picksize" component={PickSize} />
            <Route exact path="/samplesdetail" component={SampleDetail} />
            <Route
              exact
              path="/freesample/confirm"
              component={ConfirmFreeSample}
            />
            <Route exact path="/freesample/feedback" component={Feedback} />
            {/* upload */}
            <Route exact path="/uploadReceipt" component={UploadReceipt} />
            <Route
              exact
              path="/uploadReceipt/success"
              component={UploadReceiptSuccess}
            />
            <Route
              exact
              path="/uploadReceipt/producttable"
              component={ProductTable}
            />
            {/* unchecked */}
            <Route exact path="/inbox" component={Inbox} />
            {/* <Route exact path="/waystocollect" component={WaysToCollect} /> */}
            <Redirect to="/dashboard" />
          </Switch>
        ) : user && isAuthenticated && type === "dev" ? (
          <Switch>
            <Route
              exact
              path="/admin/brand/dashboard"
              component={DiaperDashboard}
            />
            <Route exact path="/admin/brand/add" component={AddBrand} />
            <Route exact path="/admin/brand/edit" component={EditBrand} />
            <Route exact path="/admin/brand/details" component={BrandDetails} />
            <Route exact path="/admin/brand/assign" component={BrandAssign} />
            <Route
              exact
              path="/admin/sample/dashboard"
              component={SampleDashboard}
            />
            <Route exact path="/admin/sample/add" component={AddSample} />
            <Route exact path="/admin/sample/edit" component={EditSample} />
            <Route
              exact
              path="/admin/catalog/dashboard"
              component={ItemDashboard}
            />
            <Route
              exact
              path="/admin/pregnant/dashboard"
              component={PregnantDashboard}
            />
            <Route exact path="/admin/item/add" component={AddItem} />
            <Route exact path="/admin/item/edit" component={EditItem} />
            <Route
              exact
              path="/admin/pregnant/add"
              component={AddItemPregnant}
            />
            <Route
              exact
              path="/admin/pregnant/editPregnant"
              component={EditItemPregnant}
            />
            <Route
              exact
              path="/admin/voucher/dashboard"
              component={VoucherDashboard}
            />
            <Route
              exact
              path="/admin/voucher/type/add"
              component={AddVoucherType}
            />
            <Route
              exact
              path="/admin/voucher/batch/details"
              component={BatchDetails}
            />
            <Route
              exact
              path="/admin/voucher/upload"
              component={UploadVoucher}
            />
            <Route
              exact
              path="/admin/voucher/details"
              component={VoucherDetails}
            />
            <Route
              exact
              path="/admin/scancode/dashboard"
              component={CodeDashboard}
            />
            <Route
              exact
              path="/admin/scancode/details"
              component={CodeDetails}
            />
            <Route
              exact
              path="/admin/scancode/generate"
              component={GenerateCodes}
            />
            <Route
              exact
              path="/admin/news/dashboard"
              component={NewsDashboard}
            />
            <Route
              exact
              path="/admin/article/dashboard"
              component={ArticleDashboard}
            />
            <Route
              exact
              path="/admin/contest/dashboard"
              component={ContestDashboard}
            />
            <Route
              exact
              path="/admin/blog/dashboard"
              component={BlogDashboard}
            />
            <Route
              exact
              path="/admin/journey/dashboard"
              component={JourneyDashboard}
            />
            <Route
              exact
              path="/admin/wishingtree/dashboard"
              component={WishingTreeDashboard}
            />
            <Route exact path="/admin/news/add" component={AddNews} />
            <Route exact path="/admin/news/edit" component={EditNews} />
            <Route exact path="/admin/journey/edit" component={EditJourney} />
            <Route exact path="/admin/contest/edit" component={EditContest} />
            <Route exact path="/admin/article/edit" component={EditArticle} />
            <Route exact path="/admin/blog/edit" component={EditBlog} />
            <Route exact path="/admin/article/add" component={AddArticle} />
            <Route exact path="/admin/contest/add" component={AddContest} />
            <Route exact path="/admin/blog/add" component={AddBlog} />
            <Route
              exact
              path="/admin/wishingtree/add"
              component={AddWishingTree}
            />
            <Route
              exact
              path="/admin/wishingtree/edit"
              component={EditWishingTree}
            />
            <Route
              exact
              path="/admin/sample/survey/dashboard"
              component={SampleQuestionDashboard}
            />
            <Route
              exact
              path="/admin/sample/survey/add"
              component={AddQuestion}
            />
            <Route
              exact
              path="/admin/donate/dashboard"
              component={DonateDashboard}
            />
            <Route exact path="/admin/donate/add" component={AddDonate} />
            <Route exact path="/admin/account" component={Account} />
            {/* <Route exact path="/admin/account/edit" component={EditAccount} /> */}
            <Route exact path="/admin/pointshop" component={AdminPointShop} />
            <Route
              exact
              path="/admin/pointhistory"
              component={AdminPointHistory}
            />
            <Route exact path="/admin/userreport" component={PointUserReport} />
            {/* <Route exact path="/admin/inbox" component={AdminInbox} /> */}
            <Route exact path="/admin/freesample" component={AdminFreeSample} />
            <Route exact path="/admin/scan" component={AdminScan} />
            <Route
              exact
              path="/admin/promoter/dashboard"
              component={PromoterDashboard}
            />
            <Route exact path="/admin/promoter/add" component={AddPromoter} />
            <Route exact path="/admin/promoter/edit" component={EditPromoter} />
            <Route
              exact
              path="/admin/massCredit/dashboard"
              component={MassCreditDashboard}
            />
            <Route exact path="/admin/massCredit/new" component={MassCredit} />
            <Route
              exact
              path="/admin/massCredit/details"
              component={MassCreditDetails}
            />
            <Route exact path="/admin/fitathon" component={AdminActivity} />
            <Route
              exact
              path="/admin/bulkCreate/users/dashboard"
              component={BulkCreateUsersDashboard}
            />
            <Redirect to="/admin/brand/dashboard" />
          </Switch>
        ) : user && isAuthenticated && type === "superadmin" ? (
          <Switch>
            <Route exact path="/admin/account" component={Account} />
            <Route exact path="/admin/account/edit" component={EditAccount} />
            {/* <Route exact path="/admin/brand/dashboard" component={DiaperDashboard} /> */}
            <Redirect to="/admin/account" />
          </Switch>
        ) : (
          <h1>404</h1>
        )}
      </Switch>
    </div>
    // {/* </BrowserRouter> */}
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    isAuthenticated: state.userReducer.isAuthenticated,
    type: state.userReducer.type,
    language: state.pageReducer.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkSession: () => dispatch(checkSession()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
